import axios from "axios";
import Swal from "sweetalert2";
import SingHelper from "../../utils/SingHelper";
import schemaCourse from "../../tables/ingresso";
import schemaClass from "../../tables/ingresso";

export const getHeaders = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("Localstorage http: ",user);
    return {
        headers: {
            "Token-Authorization": user.USER_TOKEN,
            "Is-Api": true,
            "Entity-Public-Token": "host.com.br"
        }
    };
}


export const handlerSave = async (data, args) => {    
    console.log('%c DADOS ENVIADOS PRA API: ', 'color: #999; background-color: yellow;', SingHelper.sanitizeObject(data,schemaCourse),data,schemaCourse);

    if (args.action == "create") {
        
        return await axios
            .post(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso`, SingHelper.sanitizeObject(data,schemaCourse), getHeaders())
            .then((r) => {
                return { success: true, status: r.data.status, result: r.data.result };
            }).catch(r => {
                return { success: false, status: r.response.data.status, resume: r.response.data.resume };                
            });
    } else {
        console.log("[[[[ data,schemaCourse ]]]]",SingHelper.sanitizeObject(data,schemaCourse));
        return await axios
            .patch(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso/${args.params.id}`, SingHelper.sanitizeObject(data,schemaCourse), getHeaders())
            .then((r) => {
                return { success: true, status: r.data.status, result: r.data.result };
            }).catch(r => {
                return { success: false, status: r.response.data.status, resume: r.response.data.resume };                
            });

    }
}

export const handlerSaveClass = async (data, args) => {    
    console.log('%c DADOS ENVIADOS PRA API: ', 'color: #999; background-color: yellow;', SingHelper.sanitizeObject(data,schemaClass),data,schemaClass);

    if (args.action == "create") {
        
        return await axios
            .post(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso`, SingHelper.sanitizeObject(data,schemaClass), getHeaders())
            .then((r) => {
                return { success: true, status: r.data.status, result: r.data.result };
            }).catch(r => {
                return { success: false, status: r.response.data.status, resume: r.response.data.resume };                
            });
    } else {
        console.log("[[[[ data,schemaClass ]]]]",SingHelper.sanitizeObject(data,schemaClass));
        return await axios
            .patch(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso/${args.params.id}`, SingHelper.sanitizeObject(data,schemaClass), getHeaders())
            .then((r) => {
                return { success: true, status: r.data.status, result: r.data.result };
            }).catch(r => {
                return { success: false, status: r.response.data.status, resume: r.response.data.resume };                
            });

    }
}

export const crudDelete = async (id, args) => {    
    console.log('%c DADOS ENVIADOS PRA API [DELETE]: ', 'color: #999; background-color: yellow;',id);   
        console.log("DELETE",process.env);
        return await axios
            .delete(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/${args.table}/${id}`, getHeaders())
            .then((r) => {
                return { success: true, status: r.data.status, result: r.data.result };
            }).catch(r => {
                return { success: false, status: r.response.data.status, resume: r.response.data.resume };
            });
    
}