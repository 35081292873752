import React, { useContext } from 'react'

import { Link} from 'react-router-dom'
import { AuthContext } from '../Contexts/Auth/AuthContext'

function Aside() {
    const { provider_user } = useContext(AuthContext)
    return (
        <aside className="page-sidebar">
            <div className="page-logo">
                <Link className="page-logo-link press-scale-down d-flex align-items-center position-relative" data-toggle="modal" data-target="#modal-shortcut">
                    <img src="/assets/img/logo.png" alt="Córtex - Beta" aria-roledescription="logo" />
                    <span className="page-logo-text mr-1">Gmads - Beta</span>
                    <span className="position-absolute text-white opacity-50 small pos-top pos-right mr-2 mt-n2"></span>
                    <i className="fal fa-angle-down d-inline-block ml-1 fs-lg color-primary-300"></i>
                </Link>
            </div>

            <nav id="js-primary-nav" className="primary-nav" role="navigation">
                <div className="nav-filter">
                    <div className="position-relative">
                        <input type="text" id="nav_filter_input" placeholder="Filter menu" className="form-control"/>
                        <Link /* onClick="return false;" */ className="btn-primary btn-search-close js-waves-off" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar">
                            <i className="fal fa-chevron-up"></i>
                        </Link>
                    </div>
                </div>
                <div className="info-card">                    
                    <img src={`//cdn.singularis.com.br/img/users/128x128/${provider_user.USER_IMG}`} className="profile-image rounded-circle" alt="Usuário" />
                    <div className="info-card-text">
                        <Link className="d-flex align-items-center text-white">
                            <span className="text-truncate text-truncate-sm d-inline-block">
                            {provider_user.USER_SHORT_NAME}
                            </span>
                        </Link>
                        <span className="d-inline-block text-truncate text-truncate-sm">Teresina, Piauí</span>
                    </div>
                    <img src="/assets/img/card-backgrounds/cover-2-lg.png" className="cover" alt="cover" />
                    <Link /* onClick="return false;" */ className="pull-trigger-btn" data-action="toggle" data-class="list-filter-active" data-target=".page-sidebar" data-focus="nav_filter_input">
                        <i className="fal fa-angle-down"></i>
                    </Link>
                </div>
                <ul id="js-nav-menu" className="nav-menu">
                    <li className="active open">
                        <Link data-filter-tags="application intel">
                            <i className="fal fa-info-circle"></i>
                            <span className="nav-link-text" data-i18n="nav.application_intel">Cadastros</span>
                        </Link>
                        <ul>
                            <li>
                                <Link to="/admin/register/colaborador/show" title="Cadastrar ingresso" data-filter-tags="application intel analytics dashboard">
                                    <span className="nav-link-text" data-i18n="nav.application_intel_analytics_dashboard">Colaboradores</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/register/folha_pgto/show?listall=true" title="Folha de pagamento" data-filter-tags="application intel marketing dashboard">
                                    <span className="nav-link-text" data-i18n="nav.application_intel_marketing_dashboard">Folha de pagamento</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/register/lancamentos/show?listall=true" title="Lançamentos" data-filter-tags="application intel marketing dashboard">
                                    <span className="nav-link-text" data-i18n="nav.application_intel_marketing_dashboard">Lançamentos</span>
                                </Link>
                            </li>
                            {/* <li>
                                <Link title="Introduction" data-filter-tags="application intel introduction">
                                    <span className="nav-link-text" data-i18n="nav.application_intel_introduction">Introduction</span>
                                </Link>
                            </li>
                            <li className="active">
                                <Link title="Privacy" data-filter-tags="application intel privacy">
                                    <span className="nav-link-text" data-i18n="nav.application_intel_privacy">Privacy</span>
                                </Link>
                            </li> */}
                            
                        </ul>
                    </li>
                    {/* <li>
                        <Link title="Theme Settings" data-filter-tags="theme settings">
                            <i className="fal fa-cog"></i>
                            <span className="nav-link-text" data-i18n="nav.theme_settings">Theme Settings</span>
                        </Link>
                        <ul>
                            <li>
                                <Link title="How it works" data-filter-tags="theme settings how it works">
                                    <span className="nav-link-text" data-i18n="nav.theme_settings_how_it_works">How it works</span>
                                </Link>
                            </li>
                            <li>
                                <Link title="Layout Options" data-filter-tags="theme settings layout options">
                                    <span className="nav-link-text" data-i18n="nav.theme_settings_layout_options">Layout Options</span>
                                </Link>
                            </li>
                            <li>
                                <Link title="Skin Options" data-filter-tags="theme settings skin options">
                                    <span className="nav-link-text" data-i18n="nav.theme_settings_skin_options">Skin Options</span>
                                </Link>
                            </li>
                            <li>
                                <Link title="Saving to Database" data-filter-tags="theme settings saving to database">
                                    <span className="nav-link-text" data-i18n="nav.theme_settings_saving_to_database">Saving to Database</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Link title="Package Info" data-filter-tags="package info">
                            <i className="fal fa-tag"></i>
                            <span className="nav-link-text" data-i18n="nav.package_info">Package Info</span>
                        </Link>
                        <ul>
                            <li>
                                <Link title="Documentation" data-filter-tags="package info documentation">
                                    <span className="nav-link-text" data-i18n="nav.package_info_documentation">Documentation</span>
                                </Link>
                            </li>
                            <li>
                                <Link title="Product Licensing" data-filter-tags="package info product licensing">
                                    <span className="nav-link-text" data-i18n="nav.package_info_product_licensing">Product Licensing</span>
                                </Link>
                            </li>
                            <li>
                                <Link title="Different Flavors" data-filter-tags="package info different flavors">
                                    <span className="nav-link-text" data-i18n="nav.package_info_different_flavors">Different Flavors</span>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-title">Ferramentas e componentes</li>
                    <li>
                        <Link title="UI Components" data-filter-tags="ui components">
                            <i className="fal fa-window"></i>
                            <span className="nav-link-text" data-i18n="nav.ui_components">UI Components</span>
                        </Link>
                        <ul>
                            <li>
                                <Link title="Alerts" data-filter-tags="ui components alerts">
                                    <span className="nav-link-text" data-i18n="nav.ui_components_alerts">Alerts</span>
                                </Link>
                            </li>
                            <li>
                                <Link title="Accordions" data-filter-tags="ui components accordions">
                                    <span className="nav-link-text" data-i18n="nav.ui_components_accordions">Accordions</span>
                                </Link>
                            </li>                            
                        </ul>
                    </li> */}
                   
                    
                </ul>
                <div className="filter-message js-filter-message bg-success-600"></div>
            </nav>

            <div className="nav-footer shadow-top">
                <Link /* onClick="return false;" */ data-action="toggle" data-class="nav-function-minify" className="hidden-md-down">
                    <i className="ni ni-chevron-right"></i>
                    <i className="ni ni-chevron-right"></i>
                </Link>
                <ul className="list-table m-auto nav-footer-buttons">
                    <li>
                        <Link data-toggle="tooltip" data-placement="top" title="Logs do Chat">
                            <i className="fal fa-comments"></i>
                        </Link>
                    </li>
                    <li>
                        <Link data-toggle="tooltip" data-placement="top" title="Chat com suporte">
                            <i className="fal fa-life-ring"></i>
                        </Link>
                    </li>
                    <li>
                        <Link data-toggle="tooltip" data-placement="top" title="Fazer uma chamada">
                            <i className="fal fa-phone"></i>
                        </Link>
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default Aside;

