import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import { Link, useParams, useNavigate } from 'react-router-dom'


/* COMPONENTES */
import Add from '../../components/Add'
import MyButton from '../../components/MyButton'
import SweetButton from '../../components/SweetButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import TabNavigation from '../../components/TabNavigation';
import CustomInputText from '../../components/CustomInputText';
import CustomDropdown from '../../components/CustomDropdown';
import CustomInputNumber from '../../components/CustomInputNumber';
import CustomInputMask from '../../components/CustomInputMask';
import CustomInputMonetary from '../../components/CustomInputMonetary';
import { CustonSwal } from '../../components/Swal';

/* UTILITÁRIOS */
import Permission from '../../utils/Permission.js'
import SingHelper from '../../utils/SingHelper';

import * as Yup from 'yup';
import axios from 'axios'

import schemaClass from '../../tables/ingresso'
import MyYup from '../../utils/MyYup';
import Alert from '../../components/Alert.jsx';

const myYup = new MyYup({ teste: "teste" });

myYup.setSchema(schemaClass);


const FragmentSearch = forwardRef((props, ref) => {
    const navigate = useNavigate();



    const getHeaders = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
            headers: {
                "Token-Authorization": user.USER_TOKEN,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br"
            }
        };
    }




    /* VARIAVEIS */
    const parentCallback = props.parentCallback;




    const initialValues = {
        "ingresso_id": "",
        "client_name": "",
        "_created_user_id": "",
        "_created_at": "",
        "cpf": "",
        "email": "",
        "telefone": "",
        "ingresso_status_id": "",

    };



    const [getAction, setAction] = useState('create');
    const [getModeForm, setModeForm] = useState(props.mode);


    const [status, setStatus] = useState([])
    const [courseType, setCourseType] = useState([])
    const [courseList, setCourseList] = useState([])

    /* COMPONENTES PRIME-REACT */
    const [selectedCity, setSelectedCity] = useState();

    /* COMPONENTES PRIME-REACT */
    const [isActiveLoadingDiv, activeLoadingDiv] = useState(false);
    const [isActiveLoader, activeLoader] = useState(false)
    const [onListener, setListener] = useState(false)
    const [stateDennyPage, activeDennyPage] = useState(false)
    const [isActiveList, activeList] = useState(false)
    const [statePanels, activePanels] = useState(true)
    const [getActiveTab, setActiveTab] = useState('all')
    const [getCustonTabTitle, setCustonTabTitle] = useState('Novo')
    /* USE STATE QUE CONTROLA AS ABAS */
    const [getList, setList] = useState([])
    const [getTbInit, setTbInit] = useState([])
    const [getFormData, setFormData] = useState(initialValues);
    const [swalProps, setSwalProps] = useState({});
    /* DIVERSOS */
    const [activeButton, setActiveButton] = useState("");


    const toast = useRef(null);
    const copyLink = useRef("");

    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };




    const handleSearch = () => {
        setActiveButton("btnSearch")

        let values = SingHelper.clearObject(formik.values);
        activeLoader(true);
        console.log("FRAGMENTO DE BUSCA PAYLOAD: ", values);

        console.log("vERIFICA SE É VAZIO:", SingHelper.isEmpty(values));
        /* Verifica se o objeto é vazio */
        if (SingHelper.isEmpty(values)) {
            CustonSwal(1000);
            activeLoader(false);
            return false;
        }

        /* prepara pra enviar o post ou patch */
        const instance = axios.create({
            baseURL: process.env.REACT_APP_HOST_API_CLIENT,
            timeout: 5000,
            headers: getHeaders().headers
        });
        const httpMethod = getAction;

        // Dados que você deseja enviar ou atualizar (depende do verbo)
        const data = schemaClass.beforeSend(values);
        //Aqui o sistema pega todos os parametros do formulário
        let params = SingHelper.objectToUrlParams(data);
        // URL do endpoint
        const url = `/api/v1/ingresso?search=true&count_all_rows=true&${params}`; // Observe que você pode usar caminhos relativos com a instância

        console.log('data, url, method, getAction:', data, url, httpMethod, getAction);
        // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
        instance["get"](url, data)
            .then((response) => {
                console.log("Response", response);
                if (response.status === 200) {
                    activeLoader(false);
                    parentCallback({ "key": null, "payload": response.data.result, "result_row_count_total": response.data.result_row_count_total, "show_paginator": true, "action": "RELOAD", "origin": "search", "q": params });
                }
            })
            .catch((error) => {
                console.error(`Erro na solicitação ${httpMethod.toUpperCase()}:`, error);
                activeLoader(false);
            });
    }

    const formik = useFormik({
        initialValues,

        onSubmit: (values) => {
        }
    })
        ;




    const handleClickCancel = (name) => {

        activeLoader(true);
        setActiveButton(name)
        //setModeForm("post");
        formik.resetForm();
        activeLoader(false);

    };
    /* FUNÇÃO RESPONSÁVEL PELA EDIÇÃO, POPULANDO O PAYLOAD NO FORMIK */
    useImperativeHandle(ref, () => ({
        populaForm: (param) => {
            formik.setValues(param);
            setModeForm(props.mode)
            navigate(`/admin/register/page/${param.ingresso_id}/patch`);
        }
    }));


    useEffect(() => {
        //console.log('%c useEffect - [formik.values]: ', 'color: #000; background-color: green;');
        setModeForm(getModeForm)
        activeLoader(false);
    }, [formik.values])

    return (
        <div id="panel-2" className="panel">
            <div className="panel-hdr">
                <h2>
                    Busca avançada {getModeForm}
                </h2>
                <div className="panel-toolbar">
                    {/* <button className="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                                    <button className="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button> */}
                    <button className="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                </div>
            </div>
            <div className="panel-container show">

                {
                    getModeForm == "patch" && getFormData.length == 0 ?
                        <div className="panel-content">
                            <Alert text={<><strong>Sinto muito!!</strong> Não foi enviado uma informação para edição</>} icon="" className="danger" iconAlign="left" />
                        </div> : (
                            <>
                                <div className="panel-content p-0">

                                    <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation needs-validation" novalidate id="form-login">


                                        <div className="panel-content">
                                            <div className="form-row">
                                                <div className="col-md-6 mb-3">
                                                    <CustomInputText field='client_name' placeholder="Nome do aluno" label='Nome do aluno *' formik={formik} args={{ action: getAction }} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <CustomInputText field='email' placeholder="Informe o E-mail do aluno" label='Endereço de E-mail *' formik={formik} args={{ action: getAction }} />
                                                </div>
                                            </div>
                                            <div className="form-row form-group">
                                                <div className="col-md-6 mb-3">
                                                    <CustomInputMask field='cpf' placeholder="CPF do aluno" label='Cadastro Pessoa Física - CPF *' formik={formik} args={{ action: getAction, mask: "999.999.999-99" }} />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <CustomInputMask field='telefone' placeholder="(12) 3 4567-8910" label='Número para contato *' formik={formik} args={{ action: getAction, mask: "(99) 9 9999-9999" }} />

                                                </div>

                                                <div className="col-12 mb-3">
                                                    <label className="form-label" for="validationTextarea2">Observação</label>
                                                    <textarea className="form-control" id="validationTextarea2" placeholder="Texto livre pra observação" required=""></textarea>
                                                    <div className="invalid-feedback">
                                                        Informações adicionais
                                                    </div>
                                                </div>
                                                {/* <div className="col-12">
                                            <label className="form-label mb-2">Please disclose your gender profile <span className="text-danger">*</span></label>
                                            <div className="custom-control custom-radio mb-2">
                                                <input type="radio" className="custom-control-input" id="GenderMale" name="radio-stacked" required="" />
                                                <label className="custom-control-label" for="GenderMale">Male</label>
                                            </div>
                                            <div className="custom-control custom-radio mb-2">
                                                <input type="radio" className="custom-control-input" id="GenderFemale" name="radio-stacked" required="" />
                                                <label className="custom-control-label" for="GenderFemale">Female</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" className="custom-control-input" id="genderPrivate" name="radio-stacked" required="" />
                                                <label className="custom-control-label" for="genderPrivate">Prefer not to say</label>
                                                <div className="invalid-feedback">Please select at least one</div>
                                            </div>
                                        </div> */}
                                            </div>
                                        </div>
                                        <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row align-items-center bg-faded">
                                            <MyButton
                                                name="btnCancel"
                                                text="Limpar campos"
                                                onClick={() => handleClickCancel("btnCancel")}
                                                loading={isActiveLoader}
                                                disabled={isActiveLoader}
                                                activeButton={activeButton}
                                                messageLoading="Limpando..."
                                                icon="fal fa-times"
                                                btnClass="btn btn-info ml-auto" />

                                            <MyButton
                                                name="btnSearch"
                                                text="Buscar"
                                                onClick={() => handleSearch()}
                                                loading={isActiveLoader}
                                                disabled={isActiveLoader}
                                                activeButton={activeButton}
                                                messageLoading="Buscando..."
                                                icon="fal fa-search"
                                                btnClass="btn btn-primary ml-2" />
                                        </div>
                                    </form>
                                </div>
                            </>
                        )
                }

            </div>
        </div>
    );
})

export default FragmentSearch;