import React, { useEffect, useState, useRef, useContext } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { AuthContext } from "../../Contexts/Auth/AuthContext";

import MyButton from '../../components/MyButton'
import withReactContent from 'sweetalert2-react-content'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';


import video from '../../assets/video/cc.mp4'
import videowebm from '../../assets/video/cc.webm'

function Login() {

    const auth = useContext(AuthContext);

    const navigate = useNavigate();
    const params = useParams();
    const { register, handleSubmit, reset, formState } = useForm();
    const [showLoader, setShowLoader] = useState(false)
    const [showReturnLogin, setShowReturnLogin] = useState(false)

    //const [user, setUser] = useState({ email: 'root@singularis.com.br', password: '123456'})
    const { errors } = formState;

    async function goLogin(token) {
        setShowLoader(true)

        const isLogged = await auth.signin({ token: token });
        console.log("isLogged", isLogged);
        if (isLogged) {
            navigate('/admin/register/colaborador/show');
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possível fazer login',
                footer: isLogged.resume.error.error_short_description,
                showConfirmButton: true
            })
            setShowLoader(false)
        }

        setShowReturnLogin(false);
    }
    async function onSubmit(data, e) {
        console.log("submit data", data);
        e.preventDefault();
        setShowLoader(true)
        if (data.email && data.password) {
            const isLogged = await auth.signin({email:data.email, password:data.password});
            console.log("isLogged", isLogged);
            if (isLogged) {
                navigate('/admin/register/colaborador/show');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Não foi possível fazer login',
                    footer: isLogged.resume.error.error_short_description,
                    showConfirmButton: true
                })
                setShowLoader(false)
            }
        }
        setShowReturnLogin(false);

        return false;
    }
    useEffect(() => {
        // reset form with user data
        const queryString = window.location.search;
        console.log(queryString);
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get('token')
        console.log("token", token);
        if (token) {
            /* TEMPORARIO */
            goLogin(token)
        }

        reset({ email: '', password: '' });
    }, []);


    return (
        <>
            <div className="blankpage-form-field">
                <div className="page-logo m-0 w-100 align-items-center justify-content-center rounded border-bottom-left-radius-0 border-bottom-right-radius-0 px-4">
                    <Link className="page-logo-link press-scale-down d-flex align-items-center">
                        <img src="./assets/img/logo.png" alt="SmartAdmin WebApp" aria-roledescription="logo" />
                        <span className="page-logo-text mr-1">MADS - Versão Beta | 24.07.001</span>
                    </Link>
                </div>
                <div className="card p-4 border-top-left-radius-0 border-top-right-radius-0">
                    <form onSubmit={handleSubmit(onSubmit)} className="validate m-t" id="form-login">
                        <div className="form-group">
                            <label className="form-label" for="username">E-mail</label>
                            <input className={`form-control ${errors.email ? 'is-invalid' : ''}`} name="email" {...register('email')} type="email" />
                            {/* <input type="email" id="username" className="form-control" placeholder="your id or email" value="drlantern@gotbootstrap.com"/> */}
                            <span className="help-block">
                                Informe seu endereço de email
                            </span>
                        </div>
                        <div className="form-group">
                            <label className="form-label" for="password">Senha</label>
                            {/* <input type="password" id="password" className="form-control" placeholder="password" value="password123"/> */}
                            <input className={`form-control ${errors.password ? 'is-invalid' : ''}`} name="password" {...register('password')} type="password" />
                            <span className="help-block">
                                Sua senha
                            </span>
                        </div>
                        <div className="form-group text-left">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="rememberme" />
                                <label className="custom-control-label" for="rememberme"> Lembrar minha senha por 30 dias.</label>
                            </div>
                        </div>

                        <MyButton
                            text="Entrar"
                            type="submit"
                            loading={showLoader}
                            disabled={showLoader}
                            btnClass="btn btn-primary float-right"
                        />
                    </form>
                </div>
                <div className="blankpage-footer text-center">
                    <Link><strong>Esqueci minha senha</strong></Link> | <Link><strong>Quero me cadastrar</strong></Link>
                </div>
            </div>
            {/* <div className="login-footer p-2">
            <div className="row">
                <div className="col col-sm-12 text-center">
                    <i><strong>Mensagem sistema:</strong> You were logged out from 198.164.246.1 on Saturday, March, 2017 at 10.56AM</i>
                </div>
            </div>
        </div> */}
            <video poster="./assets/img/backgrounds/clouds.png" id="bgvid" autoPlay loop muted>
                <source src={videowebm} type="video/webm" />
                <source src={video} type="video/mp4" />
            </video>
        </>
    );
}

export default Login;
