import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import { Link, useParams, useNavigate } from 'react-router-dom'


/* COMPONENTES */
import MyButton from '../../components/MyButton'
import LoadingSpinner from '../../components/LoadingSpinner';
import CustomInputText from '../../components/CustomInputText';
import CustomDropdown from '../../components/CustomDropdown';
import CustomInputMonetary from '../../components/CustomInputMonetary';
import { CustonSwal } from '../../components/Swal';

/* UTILITÁRIOS */
import * as Yup from 'yup';
import axios from 'axios'

import schemaClass from '../../tables/folha_pgto'
import MyYup from '../../utils/MyYup';
import Alert from '../../components/Alert.jsx';

const myYup = new MyYup({ teste: "teste" });
myYup.setSchema(schemaClass);


const FragmentForm = forwardRef((props, ref) => {
    const navigate = useNavigate();

    const getHeaders = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
            headers: {
                "Token-Authorization": user.USER_TOKEN,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br"
            }
        };
    }
    /* VARIAVEIS */
    const parentCallback = props.parentCallback;
    const initialValues = {
        "folha_pgto_name": "",
        "centro_custo_id": "",
        "valor_total": "",
        "mes_competencia": "",
        "ano_competencia": "",
        "folha_pgto_status_id": ""
    };


    const [getModeForm, setModeForm] = useState(props.mode);
    const [isActiveLoader, activeLoader] = useState(false)
    /* USE STATE QUE CONTROLA AS ABAS */    
    const [getTbInit, setTbInit] = useState([])    
    const [getFormData, setFormData] = useState(initialValues);    
    const [activeButton, setActiveButton] = useState("");    
    const [onListener, setListener] = useState(false)    //NEW

    const validateSchema = Yup.object().shape({
        folha_pgto_name: Yup.string().required("Campo obrigatório"),
        centro_custo_id: Yup.string().required("Campo obrigatório"),
        valor_total: Yup.string().required("Campo obrigatório"),
        mes_competencia: Yup.string().required("Campo obrigatório"),
        ano_competencia: Yup.string().required("Campo obrigatório"),
        folha_pgto_status_id: Yup.string().when(getModeForm, {
            is: 'patch',
            then: (validateSchema) => validateSchema.required('Campo obrigatório'),
            otherwise: (schema) => schema.min(0)
        }),
        //cpf: Yup.string().required("Campo obrigatório"),
        //email: Yup.string().required("Campo obrigatório"),
        //telefone: Yup.string().required("Campo obrigatório")
        /* student_certificate_config_id: Yup.string().required("Campo obrigatório"),
        icp_certificate_config_id: Yup.string().required("Campo obrigatório"),
        city_id: Yup.string().required("Campo obrigatório"),
        workload: Yup.number().required("Campo obrigatório"),
        vacancy: Yup.number().min(1, "Valor deve ser maior ou igual a um").required("Campo obrigatório"),
        
        price_cash: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Valor informado é inválido!').required("Campo obrigatório"),
        price_split: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Valor informado é inválido!').required("Campo obrigatório"),
        dt_start: myYup.date().exe(),
        dt_end: myYup.date().moreThan("dt_start").exe(),
        class_modality_id: Yup.string().required("Campo obrigatório") */
    });
    

    
    const toast = useRef(null); 

    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };

    /* FUNÇÕES PRINCIPAIS*/
    /* ESSA FUNÇÃO É RESPONSÁVEL POR FAZER O RESET NO FORMIK */
    const handleClickCancel = (name) => {
        activeLoader(true);
        setActiveButton(name)
        setModeForm("post");
        formik.resetForm();
    };




    const formik = useFormik({
        initialValues,
        validationSchema: validateSchema,
        onSubmit: (payload) => {
            //console.clear();
           
            
            setActiveButton("btnSave")
            activeLoader(true);
            const _id = schemaClass.getPrimaryKey(payload) >= 1 ? `/${payload.folha_pgto_id}` : "";
            console.log("_id_id", _id);
            /* prepara pra enviar o post ou patch */
            const instance = axios.create({
                baseURL: process.env.REACT_APP_HOST_API_CLIENT,
                timeout: 5000,
                headers: getHeaders().headers
            });
            const httpMethod = getModeForm;
            
            // Dados que você deseja enviar ou atualizar (depende do verbo)
            const data = schemaClass.beforeSend(payload);
            // URL do endpoint
            const url = `/api/v1/folha_pgto${_id}`; // Observe que você pode usar caminhos relativos com a instância            
            
            console.log(" SUBMIT PAYLOAD: ", payload, data);

            
            // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
            instance[getModeForm](url, data)
                .then((response) => {                    
                    console.log("~~~~~~~~~ Response/data form~~~~~~~~");
                    console.log(response);
                    if (response.status === 201) {
                        let res = response.data;
                        CustonSwal(res.result_code);
                        activeLoader(false);

                        if (getModeForm === "post") {
                            res.payload.folha_pgto_id = res.result;
                            parentCallback({ "key": res.result, "payload": res.payload, "action": "ADD" });                            
                        }

                        if (getModeForm === "patch") {                            
                            console.log(":: DADOS DO FORMIK ::", payload);
                            setModeForm("post")
                            parentCallback({ "key": payload.folha_pgto_id, "payload": payload, "action": "UPDATE" });
                        }
                        formik.resetForm();
                    }
                    console.log(`Resposta da solicitação ${httpMethod.toUpperCase()}:`, response);
                })
                .catch((error) => {
                    console.error(`Erro na solicitação ${httpMethod.toUpperCase()}:`, error);
                    let responseError = error.response.data.resume.failure.fault;
                    CustonSwal(responseError.fault_code,responseError.fault_short_description);
                    activeLoader(false);
                });
        },
        validateOnBlur: false, // Definir validateOnBlur como true
    });


    useEffect(() => {
        //        activeLoadingDiv(true);
        (async () => {
            let headers = getHeaders();
            headers.params = {
                tables: [
                    /* {
                        table: "tb_course",
                        asc: "course_name",
                        where: "course_"
                    }, */
                    {
                        table: "tb_regedit"
                    }/* ,
                    {
                        table: "tb_centro_custo"
                    } *//* ,
                {
                    table: "tb_class_modality"
                },
                {
                    table: "tb_city"
                } */
                ]
            };
            await axios
                .get(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/regedit`, headers)
                .then(response => {
                    //console.clear();              
                    if (response.status == 200) {
                        let responseData = response.data;                        
                        setTbInit(responseData);
                    }
                })
                .catch(r => {
                    console.log("~~~~~~~~~~~~~ | catch", r);
                })
        })();
    }, []);

    useEffect(() => {        
        activeLoader(false);
        formik.setValues(getFormData);
    }, [getFormData])

    useEffect(() => {        
        console.log("achegou no effect",props);
        setModeForm(props.mode)
        setFormData(schemaClass.sanitize(props.dataForm))
        navigate(`/admin/register/folha_pgto/${props.dataForm.folha_pgto_id}/patch`);        
    }, [props.dataForm])

    return (
        <div id="panel-2" className="panel">
            <div className="panel-hdr">
                {<h2>
                    Cadastro {getModeForm}
                </h2>}
                <div className="panel-toolbar">
                    { /*<button className="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                <button className="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button> */}
                    <button className="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                </div>
            </div>
            <div className="panel-container show">
                {
                    getModeForm == "patch" && getFormData.length == 0 ?
                        <div className="panel-content">
                            <Alert text={<><strong>Sinto muito!!</strong> Não foi enviado uma informação para edição</>} icon="" className="danger" iconAlign="left" />
                        </div> : (
                            <>
                                {<div className="panel-content">
                                    {/*<div className="panel-tag">
                                    <p>Aqui vai uma descrição opcional<code>Code</code>. Continua descrição.</p>
                                    <p>Subdescrição opcional</p>
                                </div>*/}
                                </div>}
                                <div className="panel-content p-0">
                                    {getTbInit.length === 0 ? <LoadingSpinner /> :
                                        <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation needs-validation" novalidate id="form-create-update">

                                            <div className="panel-content">
                                                <div className="form-row">
                                                    {/*--- INÍCIO ---*/}
                                                    <div className="col-md-6 mb-3"><CustomInputText field='folha_pgto_name' id="validationCustom01" required placeholder="Ex.: Folha-pgto-salario-05" label='Descrição' formik={formik} args={{ action: getModeForm }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomDropdown
                                                            field="centro_custo_id"
                                                            label="Centro de custo"
                                                            options={[getTbInit, 'tb_centro_custo', 'centro_custo_name', 'centro_custo_id']}
                                                            labelField="name"
                                                            listener={onListener}
                                                            required={true}
                                                            //onBlurHandler={() => loadConfigCertificate()}
                                                            placeholder=""
                                                            formik={formik}
                                                        />

                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputMonetary field="valor_total" required placeholder="" label="Valor total" formik={formik} args={{ action: getModeForm }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomDropdown
                                                            field="mes_competencia"
                                                            label="Mês de competência"
                                                            options={[getTbInit, 'month', 'label', 'value']}
                                                            labelField="name"
                                                            listener={onListener}
                                                                //onBlurHandler={() => loadConfigCertificate()}
                                                            placeholder=""
                                                            formik={formik}
                                                        />

                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomDropdown
                                                            field="ano_competencia"
                                                            label="Ano de Competência"
                                                            options={[getTbInit, 'year', 'label', 'value']}
                                                            labelField="name"
                                                            listener={onListener}
                                                            //onBlurHandler={() => loadConfigCertificate()}
                                                            placeholder=""
                                                            formik={formik}
                                                        />

                                                    </div>
                                                    {
                                                        getModeForm == "patch" && <div className="col-md-6 mb-3">
                                                            <CustomDropdown
                                                                field="folha_pgto_status_id"
                                                                label="Status"
                                                                options={[getTbInit, 'tb_status_folha_pgto', 'status_name', 'status_id']}
                                                                labelField="name"
                                                                listener={onListener}
                                                                //onBlurHandler={() => loadConfigCertificate()}
                                                                placeholder=""
                                                                formik={formik}
                                                            />

                                                        </div>
                                                    }

                                                    {/*--- FIM ---*/}
                                                </div>
                                            </div>

                                            <div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row   bg-faded">
                                                {/* <div className="custom-control custom-checkbox flex-grow-1">
                                                    <input type="checkbox" className="custom-control-input" id="invalidCheck" required />
                                                    <label className="custom-control-label" for="invalidCheck">Cliente ciente dos termos? <span className="text-danger">*</span></label>
                                                    <div className="invalid-feedback">
                                                    Você deve selecionar o seguinte campo
                                                    </div>
                                                    </div> */}

                                                {
                                                    getModeForm == "patch" &&
                                                    <MyButton
                                                        name="btnCancel"
                                                        text="Cancelar"
                                                        type="button"
                                                        loading={isActiveLoader}
                                                        disabled={isActiveLoader}
                                                        activeButton={activeButton}
                                                        onClick={(el) => handleClickCancel("btnCancel")}
                                                        icon="fal fa-times"
                                                        btnClass="btn btn-default ml-auto" />
                                                }
                                                {
                                                    getModeForm == "patch" &&
                                                    <MyButton
                                                        name="btnPatch"
                                                        text="Novo"
                                                        type="button"
                                                        loading={isActiveLoader}
                                                        disabled={isActiveLoader}
                                                        activeButton={activeButton}
                                                        onClick={(el) => handleClickCancel("btnPatch")}
                                                        icon="fal fa-plus"
                                                        btnClass="btn btn-success ml-2" />
                                                }
                                                <MyButton
                                                    name="btnSave"
                                                    text="Salvar"
                                                    type="submit"
                                                    loading={isActiveLoader}
                                                    disabled={isActiveLoader}
                                                    activeButton={activeButton}
                                                    icon="fal fa-save"
                                                    btnClass="btn btn-primary ml-2" />

                                            </div>

                                        </form>
                                    }
                                </div>
                            </>
                        )
                }

            </div>
        </div>
    );
})

export default FragmentForm;