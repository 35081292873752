import axios from 'axios'
const myRedis ={
    connection:async (endPoint,getHeaders)=>{
        let headers = getHeaders;
        headers.params = {
            tables: [
                /* {
                    table: "tb_course",
                    asc: "course_name",
                    where: "course_"
                }, */
                {
                    table: "tb_regedit"
                }/* ,
                    {
                        table: "tb_class_modality"
                    },
                    {
                        table: "tb_city"
                    } */
            ]
        };
        return await axios
            .get(`${process.env.REACT_APP_HOST_CLIENT_REDIS}/${endPoint}`, headers)
            .then(response => {
                console.log("`${process.env.REACT_APP_HOST_CLIENT_REDIS}/api/v1/regedit` :: response", response.data);
                if (response.status == 200) {
                    return response.data;                    
                }
            })
            .catch(r => {
                console.log("~~~~~~~~~~~~~ | catch", r);
            })
    }
    
}
export default myRedis