import React, { useState, useEffect } from 'react'
import { useApi } from "../../hooks/useApi";

//Função que constroe o Provider e também permite Consumir os Dados Globais
import { AuthContext } from "../../Contexts/Auth/AuthContext";

//Componente Provider para passar os valores para os Childrens
function AuthProvider({ children }) {
  const [provider_user, setUser] = useState();
  const [provider_session, setSession] = useState();
  let provider_tables = {};
  const api = useApi();

  useEffect(() => {
    //console.clear();
    console.log("~~~~ FAZ A VERIFICAÇÃO DE SESSÃO ~~~~ - API");
    const validateToken = async () => {
      const storageUser = localStorage.getItem('user');
      const storageData = localStorage.getItem('authToken');
      const storageSession = localStorage.getItem('session');
      
      if (storageData) {
        setUser(JSON.parse(storageUser));
        setSession(JSON.parse(storageSession));
        /* const data = await api.validateToken(storageData);
        if (data.user) {
          console.log("[usuario logado]", data);
          setUser(data.user);
          setSession(storageSession);
        } */
      }
    }
    validateToken();
  }, []);

  const signin = async (email, password) => {
    const response = await api.signin(email, password);
    console.log("chegou AuthProvider", response.result);
    if (response.success) {
      if (response.result.user && response.result.token) {
        let _user = response.result.user;
console.log("DADOS DA SESSÃO DO USUÁRIO::: ",_user);
        setUser(_user);
        setToken(response.result.token);
        setSession(response.result.session)
        setLocalStorage("session", response.result.session)
        setLocalStorage("permission", response.result.permission)
        const userData = { USER_NAME: _user.USER_NAME, USER_IMG: _user.USER_IMG, USER_SHORT_NAME: _user.USER_SHORT_NAME,USER_LEVEL_NAME: _user.USER_LEVEL_NAME, USER_ID: _user.USER_ID, USER_TOKEN: _user.USER_TOKEN }

        localStorage.setItem("user", JSON.stringify(userData));
        return true;
      }
    } else {

    }

    return false;
  }

  const signout = async () => {
    console.log("signout está sendo executada.");
    setUser(null);
    setToken('');
    await api.logout();
  }

  const setToken = (token) => {
    localStorage.setItem('authToken', token);
  }

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  }

  return (
    <AuthContext.Provider value={{ provider_user, signin, signout, provider_session,provider_tables }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider