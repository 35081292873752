//import { ServicesSubscriber } from "../../services/subscriber/http"

import { UseGeneratePdf } from "../../../Domain/GeneratePdf/default";
import { ServicesFolhaPgtoItem } from "../../../Domain/Services/Folha_pgto";

//import { servicesSubscription } from "../../services/subscription_event/http";
const UseCaseGeneratePdf2 = {
    GeneratePdf: async (el) => {
        console.log("ok",el);
        
        //Agora busca os dados dos itens da folha de pgto          
        el.params=`?folha_pgto_id=${el.folha_pgto_id}&left_join=true`;

            return await ServicesFolhaPgtoItem.findAll(el).then(r => {
                console.log("::::::: response UseCaseGeneratePdf2 :::::::: ", r);
                let content = {
                    "header": el,
                    "body_header":el,
                    "body": r.data.result,
                    "body_footer":{
                        "total":el.valor_total           
                    },
                    "footer": ""
                }
                UseGeneratePdf({content:content,el:el})
                 /* let payloadSubscription = {
                    "class_id": class_id,
                    "subscriber_id": r.data.payload.subscriber_id,
                    "seller_id": a.seller_id,
                    "manager_id": a.manager_id
                }

                return servicesSubscription.save(payloadSubscription, b).then(r => {
                    console.log("::::::: response servicesSubscription :::::::: ", r);
                    return r;
                })  */
            })

        
        /* if (b.method == "patch") {
            //return await ServicesSubscriber.patch(a, b)
        }  */
    }
}

export default UseCaseGeneratePdf2;

