import React, { memo } from 'react';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';

const CustomInputText = ({ field, label, args, readonly=false, formik,required }) => {

  const handleFieldChange = (e) => {  
    
    const inputValue = args?.onlyNumbers ? e.target.value.replace(/\D/g, '') : e.target.value;
    formik.setFieldValue(field, inputValue);
    
  };
// faz a verificação pra saber se o componente sera obrigatorio ou não
let isRequired = required ? classNames({ 'is-invalid': formik.touched[field] && formik.errors[field] }) : "";
  return (
    <>
      <label htmlFor={field}>{label}</label>
      <InputText
        id={field}
        value={formik.values[field]}
        className={`form-control ${isRequired}`}
        name={field}        
        readOnly={readonly || args?.action === "view" || args?.action === "show" ? true : false}
        onBlur={formik.handleBlur}
        onChange={(e)=>handleFieldChange(e)}
      />
      {formik.touched[field] && formik.errors[field] && <div className='invalid-feedback'>{formik.errors[field]}</div>}
    </>
  );
};

export default memo(CustomInputText);
