import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../Contexts/Auth/AuthContext'

/* PRIME REACT */
import { Paginator } from 'primereact/paginator';
import { Ripple } from 'primereact/ripple';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

/* COMPONENTES */
import SweetButton from '../../components/SweetButton';
import Alert from '../../components/Alert.jsx';


/* UTILITÁRIOS */
import SingMask from '../../utils/SingMask';

/* INCLUDES */
import schemaClass from '../../tables/folha_pgto'

import axios from 'axios'


import "jspdf-autotable";
import jsPDF from "jspdf";

import { handlerSave, crudDelete } from '../../services/course/http'
/* ESTILOS CSS */
import 'primereact/resources/themes/lara-light-purple/theme.css';
import { UseGeneratePdf } from '../../Domain/GeneratePdf/default.js';
import UseCaseGeneratePdf4 from '../../Application/UseCases/Folha_pgto/UseCaseExportPdf.js';


const FragmentList = forwardRef((props, ref) => {
    const { provider_user } = useContext(AuthContext)

    //const navigate = useNavigate();
    const dataList = props.dataList;
    const parentCallback = props.parentCallback;
    const callbackPaginator = props.callbackPaginator;
    const loading = props.loading

    const [getList, setList] = useState({ payload: [] })
    const printPreview = [];
    const [getOrigin, setOrigin] = useState('ALL');
    const [getQueryParams, setQueryParams] = useState('');
    /* states de paginator */
    const [first, setFirst] = useState([0, 0, 0]);
    const [rows, setRows] = useState([10, 10, 10]);
    const [getPageCount, setPageCountOptions] = useState();
    const [getTotalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isShow_paginator, setShow_paginator] = useState(true);
    const [pageInputTooltip, setPageInputTooltip] = useState("Pressione 'Enter' para próxima página.");

    const show = () => {
        //toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };

    /* EXPORT PDF*/
    const generatePdf = async (el) => {
        console.log("recebido na função: ", el);
        /* 
        - Aqui sera enviado o elemento de folha de pgto para o UC
        */
        el.emmiter_user_name = provider_user.USER_SHORT_NAME;
        await UseCaseGeneratePdf4.GeneratePdf(el)
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Listagem de folha de pagamento";
        const headers = [["Cód. folha pgto", "Descrição", "Valor total", "Competência", "Criado em", "Status"]];

        const myFilter = printPreview.map(elt => [elt.folha_pgto_id, elt.folha_pgto_name, SingMask.toMonetary(elt.valor_total), elt.competencia, elt._created_at, elt.status_name]);

        let content = {
            startY: 50,
            head: headers,
            foot: headers,
            body: myFilter
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    const onPageChange = (e, index) => {
        e.page = e.page + 1;
        e.origin = getOrigin;
        e.q = getQueryParams;
        console.log("onPageChange: ", e);
        setFirst(first.map((f, i) => (index === i ? e.first : f)));
        setRows(rows.map((r, i) => (index === i ? e.rows : r)));
        callbackPaginator(e)
    };

    const onPageInputChange = (event, options) => {
        setPageCountOptions(options);

        setCurrentPage(event.target.value);
    };


    const onPageInputKeyDown = (event, options) => {
        console.log("onPageInputKeyDown: ", options);
        if (event.key === 'Enter') {
            const page = parseInt(currentPage);

            if (page < 0 || page > options.totalPages) {
                setPageInputTooltip(`Valor de ser entre 1 e ${options.totalPages}.`);
            } else {
                let _first = [...first];

                _first[0] = currentPage ? options.rows * (page - 1) : 0;

                setFirst(_first);
                setPageInputTooltip("Pressione 'Enter' para próxima página.");
            }
        }
    };
    const handlerDelete = async (folha_pgto_id) => {
        let ok = await crudDelete(folha_pgto_id, { table: 'folha_pgto' })
        if (ok) {
            const newgetList = getList.filter((el) => el.folha_pgto_id !== folha_pgto_id);
            setList(newgetList);
        }
    }
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        PrevPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Anterior</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Próxima</span>
                    <Ripple />
                </button>
            );
        },
        PageLinks: (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return (
                    <span className={className} style={{ userSelect: 'none' }}>
                        ...
                    </span>
                );
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            );
        },
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 'Todas', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <>
                    <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        ir para <InputText size="2" className="ml-1" value={currentPage} tooltip={pageInputTooltip} onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={(e) => onPageInputChange(e, options)} />
                    </span>
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} a {options.last} de {options.totalRecords}
                    </span>
                </>
            );
        }
    };





    useImperativeHandle(ref, () => ({
        refreshTableList: (args) => {

            setShow_paginator(args.show_paginator ?? true);
            setOrigin(args.origin ?? getOrigin);
            setQueryParams(args.q ?? getQueryParams);

            console.log('%c ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| FRAGMENT LIST :: useImperativeHandle :: refreshTableList |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~', 'color: #000; background-color: #f4c700;');

            console.log("~~ Payload recebido da página pai: ", args);

            if (args.action === "ALL") {
                setList(args)
            }

            if (args.action === "UPDATE") {
                console.log(":: useImperativeHandle :: refreshTableList :: UPDATE", args);
                setList((prevList) => ({ ...prevList, args }));
            }

            console.groupEnd();
        }
    }));

    useEffect(() => {
        console.log("HTMLLIST - useEffect getList/data: ", getList, dataList);
        setList(getList);
    }, [loading])

    useEffect(() => {
        console.log("HTMLLIST - useEffect getList/data: ", getList, dataList);
        if (dataList)
            setList(dataList);
    }, [dataList])


    return (
        <div className="row">
            <div className="col-xl-12">

                <div id="panel-1" className="panel">

                    <div className="panel-hdr">
                        <h2>
                            Listagem
                        </h2>
                        <div className="panel-toolbar">
                            <button onClick={() => exportPDF()} className="btn btn-icon btn-sm btn-success shadow-0 rounded-circle waves-effect waves-themed">
                                <i className="fal fa-file-pdf"></i>
                            </button>
                        </div>
                    </div>
                    <div className="panel-container show">
                        <div className="panel-content">

                            {loading == false ?
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando...
                                    </button>
                                </div> : (
                                    getList.payload.length == 0 ? <Alert text={<><strong>Sinto muito!!</strong> Nenhum registro encontrado</>} icon="" className="warning" iconAlign="left" /> : (

                                        <table id="dt-basic-example" className="table table-bordered table-hover table-striped w-100">
                                            <thead className="bg-primary-600">
                                                <tr>
                                                    <th>Cód. folha pgto</th>
                                                    <th>Descrição</th>
                                                    <th>Valor total</th>
                                                    <th>Competência</th>
                                                    <th>Criado em</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    getList.payload.map(el => {
                                                        el.competencia = `${el.mes_competencia}/20${el.ano_competencia}`;
                                                        el._created_at = SingMask.toDate(el._created_at, "dmYHi")

                                                        printPreview.push(el);
                                                        return (
                                                            <tr key={el.folha_pgto_id}>
                                                                <td>{el.folha_pgto_id}</td>
                                                                <td>{el.folha_pgto_name}</td>
                                                                <td>{SingMask.toMonetary(el.valor_total)}</td>
                                                                <td>{el.competencia}</td>
                                                                <td>{el._created_at}</td>
                                                                <td>{el.status_name}</td>
                                                                <td>
                                                                    <Link to={`/admin/register/folha_pgto_item/${el.folha_pgto_id}`} title="Folha de pagamento itens" className="btn btn-info btn-icon mr-2 rounded-circle waves-effect waves-themed" data-filter-tags="application intel marketing dashboard">
                                                                        <i className="fal fa-search"></i>
                                                                    </Link>
                                                                    <button onClick={() => parentCallback(el)} className="btn btn-primary btn-icon mr-2 rounded-circle waves-effect waves-themed">
                                                                        <i className="fal fa-edit"></i>
                                                                    </button>
                                                                    <button onClick={() => generatePdf(el)} className="btn btn-success btn-icon mr-2 rounded-circle waves-effect waves-themed">
                                                                        <i className="fal fa-file-pdf"></i>
                                                                    </button>

                                                                    <SweetButton props={{
                                                                        button: {
                                                                            className: "btn btn-danger btn-icon rounded-circle waves-effect waves-themed",
                                                                            icon: "fa-times",
                                                                            text: ""
                                                                        },
                                                                        swlConfig: {
                                                                            type: "dialog",
                                                                            icon: "question",
                                                                            title: "Deseja excluir",
                                                                            text: `Cód. ${el.folha_pgto_id} - ${el.folha_pgto_name}?`,
                                                                            confirmButtonText: "Sim, excluir mesmo assim.",
                                                                            confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                        },
                                                                        actions: {
                                                                            IfConfirmed: () => handlerDelete(el.folha_pgto_id), // Passar como uma função
                                                                            IfDenied: ""
                                                                        }
                                                                    }} />
                                                                </td></tr>
                                                        )
                                                    })

                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Cód. folha pgto</th>
                                                    <th>Descrição</th>
                                                    <th>Valor total</th>
                                                    <th>Mês de competência</th>
                                                    <th>Ano de competência</th>
                                                    <th>Criado em</th>
                                                    <th>Status</th>

                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>
                                                        {isShow_paginator &&
                                                            <Paginator template={template1} first={first[0]} rows={rows[0]} totalRecords={getTotalRecords} onPageChange={(e) => onPageChange(e, 0)} /* leftContent={leftContent} */ /* rightContent={rightContent} */ />
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default FragmentList;