const schemaFolha_pgto = {
    columns: {
        folha_pgto_id: {            
            onUpdate: "require",
        },

        colaborador_id: {

        },
        valor: {

        },
        mes_competencia: {

        },
        ano_competencia: {

        },
        folha_pgto_status_id: {

        },
        _created_at: {
            type: "date",   
            sendToDb:false,         
            converter: (my_date) => {
                //console.log("convertendo...",my_date);
                if (my_date.indexOf("-")) {
                    const [year, month, day] = my_date.split('-');
                    return `${day}/${month}/${year}`;
                } else {
                    const [day, month, year] = my_date.split('/');
                    return `${year}-${month}-${day}`;
                }
            }
        },
        dt_nascimento: {
            type: "date",
            converter: (my_date) => {
                //console.log("convertendo...",my_date);
                if (my_date.indexOf("-")) {
                    const [year, month, day] = my_date.split('-');
                    return `${day}/${month}/${year}`;
                } else {
                    const [day, month, year] = my_date.split('/');
                    return `${year}-${month}-${day}`;
                }
            }
        }
        
    },
    sanitize: function (data) {
        const convertedData = {};
        //console.log("Sanitize -> Data", data);
        for (const key in data) {
            //let myData = data[key] === null ? "" : data[key]
            let myData = data[key] === null || data[key] === "" ? "" : data[key]
            if (this.columns[key] && this.columns[key].converter) {                
                convertedData[key] = this.columns[key].converter(myData);
            } else {
                //console.log("aqui",this.columns[key]);
                convertedData[key] = myData;
            }
        }
        //console.log("Sanitize -> convertedData:", convertedData);
        return convertedData;
    },
    beforeSend: function (data) {
        const convertedData = {};
        ////console.clear();
        for (const key in this.columns) {
            let myData = data[key] === null || data[key] === "" ? "" : data[key]

            if (data.hasOwnProperty(key)) {
                //console.log("this.columns",this.columns);
                if (!this.columns[key].hasOwnProperty('beforeSend')) {
                    if(!this.columns[key].hasOwnProperty('sendToDb')){
                        convertedData[key] = data[key];
                    }
                }
            }
            
                //console.log(key, " NÃO EXISTE ", data[key]);
            ////console.log("myData", key, data[key]);

            /* if (data[key] && this.columns[key].converter) {
                //console.log(this.columns[key], " Existe");
                convertedData[key] = data[key].converter(myData);
            } else {
                convertedData[key] = myData;
            }  */

            //let myData = data[key] === null ? "" : data[key]
            /* let myData = data[key] === null || data[key] === "" ? "" : data[key]
            */
        }
        //console.log("beforeSend::convertedData:", convertedData);
        return convertedData;
    },
    getPrimaryKey:(obj)=>{
        //console.log("obj",obj);
        return obj.folha_pgto_id
    }
};

export default schemaFolha_pgto;
