function Footer() {
  return (
    <footer className="page-footer" role="contentinfo">
                        <div className="d-flex align-items-center flex-1 text-muted">
                            <span className="hidden-md-down fw-700">2023 © Mads Engenharia&nbsp;</span>
                        </div>
                        <div>
                            <ul className="list-table m-0">
                                <li><a href="#" className="text-secondary fw-700">Sobre</a></li>
                                <li className="pl-3"><a href="#" className="text-secondary fw-700">Licença</a></li>
                                <li className="pl-3"><a href="#" className="text-secondary fw-700">Documentação</a></li>
                                <li className="pl-3 fs-xl"><a href="#" className="text-secondary" target="_blank"><i className="fal fa-question-circle" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </footer>
  );
}

export default Footer;
