import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle, useCallback } from 'react'
import { ErrorMessage, useFormik } from 'formik';
/* PRIME REACT */
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Ripple } from 'primereact/ripple';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';

/* COMPONENTES */
import Add from '../../components/Add'
import MyButton from '../../components/MyButton'
import SweetButton from '../../components/SweetButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import TabNavigation from '../../components/TabNavigation';
import CustomInputText from '../../components/CustomInputText';
import CustomDropdown from '../../components/CustomDropdown';
import CustomInputNumber from '../../components/CustomInputNumber';
import CustomInputMask from '../../components/CustomInputMask';
import CustomInputMonetary from '../../components/CustomInputMonetary';
import Alert from '../../components/Alert.jsx';
import { CustonSwal } from '../../components/Swal';

/* UTILITÁRIOS */
import Permission from '../../utils/Permission.js'
import SingHelper from '../../utils/SingHelper';
import SingMask from '../../utils/SingMask';

/* INCLUDES */
import schemaClass from '../../tables/folha_pgto_item'

import * as Yup from 'yup';
import axios from 'axios'


import "jspdf-autotable";
import jsPDF from "jspdf";

import { handlerSave, crudDelete } from '../../services/course/http'
/* ESTILOS CSS */
import 'primereact/resources/themes/lara-light-purple/theme.css';



const FragmentList = ({ parentCallback, mode, data, loading, parentCallbackSave, callbackPaginator, formik }) => {
    //const navigate = useNavigate();

    const [getAction, setAction] = useState('create');
    const [getList, setList] = useState([])
    const [count, setCount] = useState(0);
    const [openFolhaPgto, setOpenFolhaPgto] = useState(false);


    const [valor, setValor] = useState(0);
    const [parcela, setParcela] = useState(1);
    const [descricao, setDescricao] = useState('');
    const [getOrigin, setOrigin] = useState('ALL');
    const [getQueryParams, setQueryParams] = useState('');

    /* states de paginator */
    const [first, setFirst] = useState([0, 0, 0]);
    const [rows, setRows] = useState([0, 1, 30]);
    const [getPageCount, setPageCountOptions] = useState();
    const [getTotalRecords, setTotalRecords] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const [isShow_paginator, setShow_paginator] = useState(true);
    const [isLoadPage, setLoadPage] = useState(false);

    const [pageInputTooltip, setPageInputTooltip] = useState("Pressione 'Enter' para próxima página.");

    const campoValue = useRef("");



    const show = () => {
        //toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };

    /* EXPORT PDF*/
    const generatePdf = async (el) => {
        let data = {
            "page": 2,
            "per_page": 6,
            "total": 12,
            "total_pages": 2,
            "download": true,
            "configs": {
                "template": "folha_pgto_item"
            },
            "content": {
                "folha_pgto_item": schemaClass.sanitize(el)
            },
            "support": {
                "url": "https://reqres.in/#support-heading",
                "text": "To keep ReqRes free, contributions towards server costs are appreciated!"
            },
            "qrcode": { "qrcodeOrigin": false }
        }
        await axios
            .post(`${process.env.REACT_APP_SERVER_PDF}/generateReport?download=true`, data, {
                responseType: "blob", // this is important!
                headers: { Authorization: "sometoken" },
            })
            .then(response => {
                //console.log("generatePdf", response);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
            })
            .catch(r => {
                console.log("Houve algum erro");
            })
        //http://localhost:4002/generateReport?download=true
    }
    const useForceUpdate = () => {
        const [getList, setList] = useState();
        return useCallback(() => setList({}), []);
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = "Listagem de vendas de ingresso";
        const headers = [["Cód. Ingresso", "Nome do cliente", "Cpf/Cnpj", "E-mail", "Telefone"]];

        const myFilter = getList.map(elt => [elt.folha_pgto_item_id, elt.client_name, elt.cpf, elt.email, elt.telefone]);

        let content = {
            startY: 50,
            head: headers,
            body: myFilter
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    }

    const onPageChange = (e, index) => {
        e.page = e.page + 1;
        e.origin = getOrigin;
        e.q = getQueryParams;
        console.log("onPageChange: ", e);
        setFirst(first.map((f, i) => (index === i ? e.first : f)));
        setRows(rows.map((r, i) => (index === i ? e.rows : r)));
        callbackPaginator(e)
    };

    const onPageInputChange = (event, options) => {
        setPageCountOptions(options);

        setCurrentPage(event.target.value);
    };


    const onPageInputKeyDown = (event, options) => {
        console.log("onPageInputKeyDown: ", options);
        if (event.key === 'Enter') {
            const page = parseInt(currentPage);

            if (page < 0 || page > options.totalPages) {
                setPageInputTooltip(`Valor de ser entre 1 e ${options.totalPages}.`);
            } else {
                let _first = [...first];

                _first[0] = currentPage ? options.rows * (page - 1) : 0;

                setFirst(_first);
                setPageInputTooltip("Pressione 'Enter' para próxima página.");
            }
        }
    };
    const handlerDelete = async (folha_pgto_item_id) => {
        let ok = await crudDelete(folha_pgto_item_id, { table: 'folha_pgto_item' })
        if (ok) {
            const newgetList = getList.filter((el) => el.folha_pgto_item_id !== folha_pgto_item_id);
            setList(newgetList);
        }
    }
    const getHeaders = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
            headers: {
                "Token-Authorization": user.USER_TOKEN,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br"
            }
        };
    }
    /* 
!!!!!!!! NÃO DELETAR ESSE CÓDIGO !!!!!!!!!
const leftContent = <Button type="button" icon="pi pi-star" className="p-button-outlined" />;
const rightContent = <Button type="button" onClick={handleBtnSearchPaginator} icon="pi pi-search" />; */
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        PrevPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Anterior</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Próxima</span>
                    <Ripple />
                </button>
            );
        },
        PageLinks: (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return (
                    <span className={className} style={{ userSelect: 'none' }}>
                        ...
                    </span>
                );
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            );
        },
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 'Todas', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <>
                    <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        ir para <InputText size="2" className="ml-1" value={currentPage} tooltip={pageInputTooltip} onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={(e) => onPageInputChange(e, options)} />
                    </span>
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} a {options.last} de {options.totalRecords}
                    </span>
                </>
            );
        }
    };

    /* useImperativeHandle(ref, () => ({
        refreshTableList: (args) => {

            setShow_paginator(args.show_paginator ?? true);
            setOrigin(args.origin ?? getOrigin);
            setQueryParams(args.q ?? getQueryParams);

            console.log('%c HTML LIST ', 'color: #000; background-color: green;');
            console.group("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| HTML LIST :: useImperativeHandle :: refreshTableList |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
            console.log("~~ Args: ", args);
            if (args.action === "ADD") {
                console.log("::::::::: useImperativeHandle ::::::::: refreshTableList :: ADD");
                console.log(args.payload);
                setTotalRecords(args.result_row_count_total)
                setList(args.payload)
                //useForceUpdate();
            }

            if (args.action === "ALL" || args.action === "RELOAD") {
                console.log("::::::::: useImperativeHandle ::::::::: refreshTableList :: ALL");
                setTotalRecords(args.result_row_count_total)
                setList(args.payload)
            }

            if (args.action === "UPDATE") {
                console.log(":: useImperativeHandle :: refreshTableList :: UPDATE", args);
                //setList([...getList.filter((el) => el.folha_pgto_item_id !== args.key), args.payload])
                // Encontrar o índice do objeto no array com base na condição
                const indiceAlvo = getList.findIndex(el => el.folha_pgto_item_id === args.key);

                // Verificar se o índice é válido
                if (indiceAlvo !== -1) {
                    // Clonar o array para evitar a mutação direta
                    const novogetList = [...getList];

                    // Atualizar o objeto no índice alvo com os novos dados
                    novogetList[indiceAlvo] = {
                        ...novogetList[indiceAlvo],
                        ...args.payload,
                    };

                    // Atualizar o array original
                    setTotalRecords(novogetList.length)
                    setList(novogetList);

                }
            }
            console.groupEnd();
        }
    })); */

    useEffect(() => {
        console.log("HTMLLIST - useEffect getList/data: ", getList, data);
        setList(getList);
    }, [loading])

    useEffect(() => {
        console.log("TESTANDO USEEFFECT");
    })

    useEffect(() => {
        //console.clear();

        if (isLoadPage == 123123) {
            const folha_pgto_id = window.location.href.split("/").pop();

            console.log("searchParams", folha_pgto_id);
            (async () => {
                let headers = getHeaders();
                headers.params = {
                    tables: [
                        /* {
                            table: "tb_course",
                            asc: "course_name",
                            where: "course_"
                        }, */
                        {
                            table: "tb_regedit"
                        }/* ,
                        {
                            table: "tb_class_modality"
                        },
                        {
                            table: "tb_city"
                        } */
                    ]
                };
                await axios
                    .get(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/folha_pgto/${folha_pgto_id}`, headers)
                    //.get(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/folha_pgto/${folha_pgto_id}`, headers)
                    .then(response => {
                        ////console.clear();              
                        if (response.status == 200) {
                            let responseData = response.data.result;
                            console.log("FOLHA DE PAGAMENTO", responseData[0].folha_pgto_status_id);
                            if (responseData[0].folha_pgto_status_id == "50301") {
                                setOpenFolhaPgto(true);

                            }
                        }
                    })
                    .catch(r => {
                        console.log("~~~~~~~~~~~~~ | catch", r);
                    })
            })();
        } else {
            setLoadPage(true)
        }
        console.log('%c ---------------------------------------| HTML LIST :: USEREFFECT [DATA] |---------------------------------------', 'color: #000; background-color: #f4c700;');
        console.log("getList", data);
        if (data.length == 0)
            setList([])
        else
            setList(data.payload)
    }, [data])

    const [itens, setItens] = useState([
        {
            id: 1,
            nome: "Item 1",
        },
        {
            id: 2,
            nome: "Item 2",
        },
    ]);


    const handleChange = (e) => {
        console.log("handleChange", e);
    }



    return (
        <div className="row">
            <div className="col-xl-12">

                <div id="panel-1" className="panel">

                    <div className="panel-hdr">
                        <h2>
                            Listagem
                        </h2>
                        <div className="panel-toolbar">
                            <button onClick={() => exportPDF()} className="btn btn-icon btn-sm btn-success shadow-0 rounded-circle waves-effect waves-themed">
                                <i className="fal fa-file-pdf"></i>
                            </button>
                        </div>
                    </div>
                    <div className="panel-container show">
                        <div className="panel-content">

                            {loading == false ?
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando...
                                    </button>
                                </div> : (
                                    getList.length == 0 ? <Alert text={<><strong>Sinto muito!!</strong> Nenhum registro encontrado</>} icon="" className="warning" iconAlign="left" /> : (
                                        <div className=' table-responsive'>
                                            <table id="dt-basic-example" className="table table-bordered table-hover table-striped w-100">
                                                <thead className="bg-primary-600">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Filial</th>
                                                        <th nowrap="nowrap">Data esperada/vencimento</th>
                                                        <th nowrap="nowrap">Data realizada/Data pagamento</th>
                                                        <th nowrap="nowrap">Nº parcelas</th>
                                                        <th nowrap="nowrap">Seq</th>
                                                        <th nowrap="nowrap">Plano de conta</th>
                                                        <th nowrap="nowrap">Lançamento</th>
                                                        <th nowrap="nowrap">Valor receber</th>
                                                        <th nowrap="nowrap">Valor recebido</th>
                                                        <th nowrap="nowrap">Valor pagar</th>
                                                        <th nowrap="nowrap">Valor pago</th>
                                                        <th nowrap="nowrap">Fundo</th>
                                                        <th nowrap="nowrap">Identificador de grupo</th>
                                                        <th nowrap="nowrap">Lançado por</th>
                                                        <th nowrap="nowrap">Lançado em</th>
                                                        <th nowrap="nowrap">Atualizado por</th>
                                                        <th nowrap="nowrap">Atualizado em</th>
                                                        <th nowrap="nowrap">Nº da proposta</th>
                                                        <th nowrap="nowrap">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        getList.map((el, i) => (

                                                            <tr key={i}>
                                                                <td>{el.lancamento_id}</td>
                                                                <td>{el.filial_id==1?'Teresina':'Bacabal'}</td>
                                                                <td>{SingMask.toDate(el.dt_pgto_previsao, "dmY")}</td>
                                                                <td>{SingMask.toDate(el.dt_pgto, "dmY")}</td>
                                                                <td>{el.parcelas}</td>
                                                                <td nowrap="nowrap">{el.seq}</td>
                                                                <td nowrap="nowrap">{el.plano}</td>
                                                                <td nowrap="nowrap">{el.lancamento}</td>
                                                                <td>{SingMask.toMonetary(el.valor_receber)}</td>
                                                                <td>{SingMask.toMonetary(el.valor_recebido)}</td>
                                                                <td>{SingMask.toMonetary(el.valor_pagar)}</td>
                                                                <td>{SingMask.toMonetary(el.valor_pago)}</td>
                                                                <td>{el.caixa_id}</td>
                                                                <td>{el.lancamento_grupo}</td>
                                                                <td nowrap="nowrap">{el.created_user_name}</td>
                                                                <td nowrap="nowrap">{SingMask.toDate(el._created_at,"dmYHi")}</td>
                                                                <td nowrap="nowrap">{el.updated_user_name}</td>
                                                                <td nowrap="nowrap">{SingMask.toDate(el._updated_at,"dmYHi")}</td>
                                                                <td>{el.proposal_id}</td>                                                                
                                                                <td>{el.status_name}</td>
                                                                </tr>
                                                        ))
                                                    }
                                                </tbody>
                                                <tfoot>                                                   
                                                    <tr>
                                                        <td colSpan={22}>
                                                            {isShow_paginator &&
                                                                <Paginator template={template1} first={first[0]} rows={rows[0]} totalRecords={getTotalRecords} onPageChange={(e) => onPageChange(e, 0)} /* leftContent={leftContent} */ /* rightContent={rightContent} */ />
                                                            }
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FragmentList;