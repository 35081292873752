import React from 'react';
import Swal from 'sweetalert2';

const SweetButton = ({ props }) => {
    const showAlert = () => {

        if (props.swlConfig.type == "dialog")
        {

            Swal.fire({
                icon: props.swlConfig.icon,
                title: props.swlConfig.title,
                text: props.swlConfig.text ?? '',
                confirmButtonText: props.swlConfig.confirmButtonText,
                confirmButtonColor: props.swlConfig.confirmButtonColor ?? 'blue',
                showCancelButton: props.swlConfig.showCancelButton ?? true,
            }).then((result) => {
                if (result.isConfirmed) {
                    props.actions.IfConfirmed();
                } else if (result.isDenied) {
                    props.actions.IfDenied();
                }
            })
        }
    };

    return (
        <>
            <button className={`btn ${props.button.className}`} title={`${props.button.title ?? "Botão"}`} onClick={showAlert}><i className={`fal ${props.button.icon}`}></i> {props.button.text}</button>
        </>
    );
};

export default SweetButton;
