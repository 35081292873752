import React, { useEffect, useState, useRef, useContext } from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom'
import { ErrorMessage, useFormik } from 'formik';

import { useLocation } from 'react-router'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { loadFromLocalStorage } from '../../utils/localStorageUser'

import Aside from '../../fragments/Aside';
import Header from '../../fragments/Header';
import Footer from '../../fragments/Footer';

/* UTILITÁRIOS */
import Permission from '../../utils/Permission.js'
import SingHelper from '../../utils/SingHelper';

/* COMPONENTES */
import Add from '../../components/Add'
import MyButton from '../../components/MyButton'
import SweetButton from '../../components/SweetButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import TabNavigation from '../../components/TabNavigation';
import CustomInputText from '../../components/CustomInputText';
import CustomDropdown from '../../components/CustomDropdown';
import CustomInputNumber from '../../components/CustomInputNumber';
import CustomInputMask from '../../components/CustomInputMask';
import CustomInputMonetary from '../../components/CustomInputMonetary';
import { CustonSwal } from '../../components/Swal';
import axios from 'axios'
import Swal from 'sweetalert2'
import * as Yup from 'yup';

import "jspdf-autotable";
import jsPDF from "jspdf";


import { handlerSave, crudDelete } from '../../services/course/http'
import Denny from '../Denny/Denny';
import myJson from '../../utils/json.json';

import schemaClass from '../../tables/ingresso'
import MyYup from '../../utils/MyYup';

const myYup = new MyYup({ teste: "teste" });

myYup.setSchema(schemaClass);
function Ingresso() {
    const initialValues = {
        "ingresso_id": "",
        "client_name": "",
        "_created_user_id": "",
        "_created_at": "",
        "cpf": "",
        "email": "",
        "telefone": "",
        "ingresso_status_id": "",

    };
    const [getAction, setAction] = useState('create');

    const validateSchema = Yup.object().shape({
        client_name: Yup.string().required("Campo obrigatório"),
        cpf: Yup.string().required("Campo obrigatório"),
        email: Yup.string().required("Campo obrigatório"),
        telefone: Yup.string().required("Campo obrigatório")
        /* student_certificate_config_id: Yup.string().required("Campo obrigatório"),
        icp_certificate_config_id: Yup.string().required("Campo obrigatório"),
        city_id: Yup.string().required("Campo obrigatório"),
        workload: Yup.number().required("Campo obrigatório"),
        vacancy: Yup.number().min(1, "Valor deve ser maior ou igual a um").required("Campo obrigatório"),
        class_status_id: Yup.string().when(getAction, {
            is: 'patch',
            then: (validateSchema) => validateSchema.required('Campo obrigatório'),
            otherwise: (schema) => schema.min(0)
        }),
        price_cash: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Valor informado é inválido!').required("Campo obrigatório"),
        price_split: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Valor informado é inválido!').required("Campo obrigatório"),
        dt_start: myYup.date().exe(),
        dt_end: myYup.date().moreThan("dt_start").exe(),
        class_modality_id: Yup.string().required("Campo obrigatório") */
    });
    const [data, setData] = useState('');

    const [status, setStatus] = useState([])
    const [courseType, setCourseType] = useState([])
    const [courseList, setCourseList] = useState([])

    /* COMPONENTES PRIME-REACT */
    const [selectedCity, setSelectedCity] = useState();

    /* COMPONENTES PRIME-REACT */
    const [isActiveLoadingDiv, activeLoadingDiv] = useState(false);
    const [isActiveLoader, activeLoader] = useState(false)
    const [onListener, setListener] = useState(false)
    const [stateDennyPage, activeDennyPage] = useState(false)
    const [isActiveList, activeList] = useState(false)
    const [statePanels, activePanels] = useState(true)
    const [getActiveTab, setActiveTab] = useState('all')
    const [getCustonTabTitle, setCustonTabTitle] = useState('Novo')

    /* USE STATE QUE CONTROLA AS ABAS */
    const [getList, setList] = useState([])
    const [getTbInit, setTbInit] = useState([])
    const [getFormData, setFormData] = useState(initialValues);
    const toast = useRef(null);
    const copyLink = useRef("");
    const [swalProps, setSwalProps] = useState({});





    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };



    const formik = useFormik({
        initialValues,
        validationSchema: validateSchema,
        onSubmit: (values) => {
            //console.clear();
            console.log("SUBMIT PAYLOAD: ", values);
            console.log("SUBMIT getPrimaryKey: ", schemaClass.getPrimaryKey(values));
            activeLoader(true);
            const _id = schemaClass.getPrimaryKey(values) >= 1 ? `/${values.ingresso_id}` : "";
            console.log("_id_id", _id);
            /* prepara pra enviar o post ou patch */
            const instance = axios.create({
                baseURL: process.env.REACT_APP_HOST_API_CLIENT,
                timeout: 5000,
                headers: getHeaders().headers
            });
            const httpMethod = getAction;

            // Dados que você deseja enviar ou atualizar (depende do verbo)
            const data = schemaClass.beforeSend(values);
            // URL do endpoint
            const url = `/api/v1/ingresso${_id}`; // Observe que você pode usar caminhos relativos com a instância

            console.log('data, url, method, getAction:', data, url, httpMethod, getAction);

            // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
            instance["post"](url, data)
                .then((response) => {
                    console.log("Response", response);
                    formik.resetForm()
                    if (response.status === 201) {
                        CustonSwal(response.data.result_code);
                        activeLoader(false);
                        console.log("getAction", getAction);
                        console.log("xyz2", SingHelper.Base64.encode(response.data.result));
                        if (getAction === "create") {
                            values.ingresso_id = response.data.result;
                            const list = [...getList, values];
                            //copyLink.current.value = process.env.REACT_APP_HOST_API_CLIENT + "/" + SingHelper.Base64.encode(response.data.result);
                            console.log("DEU CERTO2", getList, values);
                            setList(list);
                            formik.resetForm();
                        }
                    }
                    console.log(`Resposta da solicitação ${httpMethod.toUpperCase()}:`, response);
                })
                .catch((error) => {
                    console.error(`Erro na solicitação ${httpMethod.toUpperCase()}:`, error);
                    CustonSwal(error.response.data.resume.failure.fault.fault_code);
                    activeLoader(false);
                });
        },
        validateOnBlur: false, // Definir validateOnBlur como true
    });

    /* useForm */
    const SCREEN_ID = 502;
    const { setAuth, auth, provider_session } = useContext(AuthContext)
    console.log("provider_session", provider_session);
    Permission.setScreenId(SCREEN_ID);
    Permission.setCurrentClientId(provider_session.CURRENT_CLIENT_ID);
    Permission.setPermission(loadFromLocalStorage('permission'));

    const location = useLocation()
    const navigate = useNavigate();

    /* FUNÇÕES PRINCIPAIS*/
    const loadConfigCertificate = async () => {
        let headers = getHeaders();
        headers.params = {
            tables: [
                {
                    table: "tb_certificate_config"
                }
            ]
        };
        await axios
            .get(`${process.env.REACT_APP_HOST_API}/v1/init`, headers)
            .then(response => {
                console.log("GET: BD GET CONFIGURAÇÃO CERTIFICADO", response.data);
                if (response.status == 200) {
                    let responseData = response.data['tb_certificate_config'];
                    setTbInit((prevData) => ({ ...prevData, responseData }));
                    setListener(responseData);
                }
            })
            .catch(r => {

            })
    }
    const getHeaders = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
            headers: {
                "Token-Authorization": user.USER_TOKEN,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br"
            }
        };
    }

    /* EXPORT PDF*/
    const generatePdf = async (el) => {
        let data = {
            "page": 2,
            "per_page": 6,
            "total": 12,
            "total_pages": 2,
            "download":true,
            "data": [
                
            ],
            "client":{
                "ingresso_id":el.ingresso_id,
                "client_name":el.client_name,
                "cpf":el.cpf,
                "email":el.email,
                "telefone":el.telefone,
            },
            "event":{
                "event_name":`Inteligência Emocional`,
                "date":`06/11/2023`,
                "locale":`Imperatriz-MA`,
                "qrcodeOrigin":el.cpf+'-'+el.ingresso_id
                
            },
            "support": {
                "url": "https://reqres.in/#support-heading",
                "text": "To keep ReqRes free, contributions towards server costs are appreciated!"
            }
        }
        await axios
            .post(`${process.env.REACT_APP_SERVER_PDF}/generateReport?download=true`,data,{
                responseType: "blob", // this is important!
                headers: { Authorization: "sometoken" },
              })
            .then(response => {
                console.log("generatePdf", response);

                const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'file.pdf');
  document.body.appendChild(link);
  link.click();
            })
            .catch(r => {
console.log("Houve algum erro");
            })
        //http://localhost:4002/generateReport?download=true
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
    
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Listagem de vendas de ingresso";
        const headers = [["Cód. Ingresso", "Nome do cliente","Cpf/Cnpj","E-mail","Contato"]];
    
        const data = getList.map(elt=> [elt.ingresso_id, elt.client_name, elt.cpf, elt.email, elt.telefone]);
    
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
      }

    /* CARREGA A LISTA PRINCIPAL */

    const getListBd = async () => {
        console.group("getListBd::Buscando dados no banco ~> headers ", myJson.result, getHeaders());
        activeLoadingDiv(true);

        await axios
            .get(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso`, getHeaders())
            .then(response => {
                if (response.data.status == 200) {
                    console.log("GET: BD COURSES", response.data);
                    //activeLoadingDiv(false);
                    setList(response.data.result);
                }
            })
            .catch(r => {
                console.log("erro", r);
                //const resume = r.response.data.resume;
                console.clear();
                console.log("RESPOSTA", r);
                /* if (resume.failed.error.error_code == 402)
                    navigate("/logout");


                if (r.response.data.status == "401") {
                    activeDennyPage(true)
                    Swal.fire({
                        icon: 'warning',
                        title: resume.error.error_short_description,
                        confirmButtonText: 'Entendi',
                    }).then((result) => {

                        if (result.isConfirmed) {

                        } else if (result.isDenied) {

                        }
                    })
                }

                if (r.response.data.status == 401) {
                    if (resume.error.error_code == "203") {
                        Swal.fire({
                            icon: 'error',
                            title: resume.error.error_short_description,
                            confirmButtonText: 'Entendi',
                        }).then((result) => {

                            if (result.isConfirmed) {
                                navigate(`/login`);
                            } else if (result.isDenied) {

                            }
                        })
                    }
                } */



            })

        console.groupEnd();
    }

    useEffect(() => {
        console.log('%c useEffect - [localtion]: ', 'color: #000; background-color: green;');
    }, [location])

    useEffect(() => {
        console.log(`%c useEffect - [getActiveTab]: ${getActiveTab} `, 'color: #000; background-color: green;');

        if (getActiveTab === "all") {
            getListBd();
        }

    }, [getActiveTab])

    /* useEffect(() => {
        //        activeLoadingDiv(true);
        (async () => {
            let headers = getHeaders();
            headers.params = {
                tables: [
                    {
                        table: "tb_course",
                        asc: "course_name",
                        where: "course_"
                    },
                    {
                        table: "tb_status"
                    },
                    {
                        table: "tb_class_modality"
                    },
                    {
                        table: "tb_city"
                    }
                ]
            };
            await axios
                .get(`${process.env.REACT_APP_HOST_API}/v1/init`, headers)
                .then(response => {                    
                    if (response.status == 200) {
                        console.log("response", response.data);
                        let responseData = response.data;
                        setTbInit(responseData);
                    }
                })
                .catch(r => {

                })
        })();
    }, []); */

    useEffect(() => {
        console.log("USE EFFECT [formik.values]", formik.values);
        formik.setValues(getFormData);
    }, [getFormData])

    /* personalizado */
    //setAction("create");
    myYup.setAction("create");


    const handleClickTable = (element) => {
        console.log("element", element);

    }

    const handlerDelete = async (ingresso_id) => {
        let ok = await crudDelete(ingresso_id)
        if (ok) {
            const newgetList = getList.filter((el) => el.ingresso_id !== ingresso_id);
            setList(newgetList);
        }
    }

    const handleTabChange = (param) => {

        let tabSelected = typeof param === "object" ? param.tab : param;
        const myAction = typeof param === "object" ? param.action : tabSelected;

        setCustonTabTitle(param.tabLabel);
        if (!Permission.flag(myAction))
            activeDennyPage(true)
        else {
            activeDennyPage(false)
            if (typeof param === "object") {
                if (param.item.class_id) {
                    copyLink.current.value = "https://conscienciaeprosperidade.com.br/inscricao/" + SingHelper.Base64.encode(param.item.class_id);
                    setFormData(schemaClass.sanitize(param.item))
                }
            } else {
                formik.resetForm();
                setCustonTabTitle("Novo");
            }
        }
        setActiveTab(tabSelected);
        setAction(myAction);
        myYup.setAction(myAction);
    };
    return (
        <div className="page-wrapper">

            <div className="page-inner">
                <Aside />
                <div className="page-content-wrapper">
                    <Header />
                    <main id="js-page-content" role="main" className="page-content">
                        <ol className="breadcrumb page-breadcrumb">
                            <li className="breadcrumb-item"><a href="javascript:void(0);">Ingresso</a></li>
                            <li className="breadcrumb-item">Vendas</li>
                            <li className="breadcrumb-item active">Nome do evento aqui</li>
                            <li className="position-absolute pos-top pos-right d-none d-sm-block"><span className="js-get-date"></span></li>
                        </ol>
                        <div className="subheader">
                            <h1 className="subheader-title">
                                <i className='fal fa-info-circle'></i> Ingresso
                                <small>
                                    Esta página concentra todas as vendas do evento xyz.
                                </small>
                            </h1>
                        </div>

                        <div id="panel-2" className="panel">
                            <div className="panel-hdr">
                                <h2>
                                    Líder Consciente e Próspero
                                </h2>
                                <div className="panel-toolbar">
                                    {/* <button className="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                                <button className="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button> */}
                                    <button className="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                                </div>
                            </div>
                            <div className="panel-container show">
                                <div className="panel-content">
                                    <div className="panel-tag">
                                        <p>Esse pequeno fórmulário serve para cadastrar as vendas de ingressos <code>Evento xyz</code>. O evento ocorrerá dia 06/12/2023 as 19h.</p>
                                        <p>A lista de presença será feita na portaria informando o Nome completo mediante a lista de presença.</p>
                                    </div>
                                </div>
                                <div className="panel-content p-0">

                                    <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation needs-validation" novalidate id="form-login">


                                        <div className="panel-content">
                                            <div className="form-row">
                                                <div className="col-md-6 mb-3">
                                                    <CustomInputText field='client_name' id="validationCustom01" required placeholder="Nome do aluno" label='Nome do aluno *' formik={formik} args={{ action: getAction }} />
                                                </div>

                                                <div className="col-md-6 mb-3">
                                                    <CustomInputText field='email' id="validationCustom01" required placeholder="Informe o E-mail do aluno" label='Endereço de E-mail *' formik={formik} args={{ action: getAction }} />
                                                </div>
                                            </div>
                                            <div className="form-row form-group">
                                                <div className="col-md-6 mb-3">
                                                    <CustomInputMask field='cpf' id="validationCustom01" required placeholder="CPF do aluno" label='Cadastro Pessoa Física - CPF *' formik={formik} args={{ action: getAction, mask: "999.999.999-99" }} />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <CustomInputMask field='telefone' id="validationCustom01" required placeholder="(12) 3 4567-8910" label='Número para contato *' formik={formik} args={{ action: getAction, mask: "(99) 9 9999-9999" }} />

                                                </div>

                                                <div className="col-12 mb-3">
                                                    <label className="form-label" for="validationTextarea2">Observação</label>
                                                    <textarea className="form-control" id="validationTextarea2" placeholder="Texto livre pra observação" required=""></textarea>
                                                    <div className="invalid-feedback">
                                                        Informações adicionais
                                                    </div>
                                                </div>
                                                {/* <div className="col-12">
                                        <label className="form-label mb-2">Please disclose your gender profile <span className="text-danger">*</span></label>
                                        <div className="custom-control custom-radio mb-2">
                                            <input type="radio" className="custom-control-input" id="GenderMale" name="radio-stacked" required="" />
                                            <label className="custom-control-label" for="GenderMale">Male</label>
                                        </div>
                                        <div className="custom-control custom-radio mb-2">
                                            <input type="radio" className="custom-control-input" id="GenderFemale" name="radio-stacked" required="" />
                                            <label className="custom-control-label" for="GenderFemale">Female</label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                            <input type="radio" className="custom-control-input" id="genderPrivate" name="radio-stacked" required="" />
                                            <label className="custom-control-label" for="genderPrivate">Prefer not to say</label>
                                            <div className="invalid-feedback">Please select at least one</div>
                                        </div>
                                    </div> */}
                                            </div>
                                        </div>
                                        <div className="panel-content border-faded border-left-0 border-right-0 border-bottom-0 d-flex flex-row align-items-center bg-faded">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="invalidCheck" required />
                                                <label className="custom-control-label" for="invalidCheck">Cliente ciente dos termos? <span className="text-danger">*</span></label>
                                                <div className="invalid-feedback">
                                                    Você deve selecionar o seguinte campo
                                                </div>
                                            </div>

                                            <button                                                
                                                type="button"
                                                icon="fal fa-save"
                                                className="btn btn-default ml-auto" onClick={()=>formik.resetForm()}><i class="fal fa-times"></i> Cancelar</button>
                                            <MyButton
                                                text="Salvar"
                                                type="submit"
                                                loading={isActiveLoader}
                                                disabled={isActiveLoader}
                                                icon="fal fa-save"
                                                btnClass="btn btn-primary ml-2" />
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">

                                <div id="panel-1" className="panel">

                                    <div className="panel-hdr">
                                        <h2>
                                            Listagem de ingressos
                                        </h2>
                                        <div className="panel-toolbar">
                                            <button onClick={()=>exportPDF()} className="btn btn-icon btn-sm btn-success shadow-0 rounded-circle waves-effect waves-themed">
                                                <i className="fal fa-file-pdf"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="panel-container show">
                                        <div className="panel-content">


                                            <table id="dt-basic-example" className="table table-bordered table-hover table-striped w-100">
                                                <thead className="bg-primary-600">
                                                    <tr>
                                                        <th>Cód. Ingresso</th>
                                                        <th>Nome do cliente</th>
                                                        <th>Cpf/Cnpj</th>
                                                        <th>E-mail</th>
                                                        <th>Contato</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {getList.length > 0 && (
                                                        getList.map(el => (
                                                            <tr key={el.ingresso_id}>
                                                                <td>{el.ingresso_id}</td>
                                                                <td>{el.client_name}</td>
                                                                <td>{el.cpf}</td>
                                                                <td>{el.email}</td>
                                                                <td>{el.contato}</td>
                                                                <td>
                                                                    <button onClick={()=>generatePdf(el)} className="btn btn-success btn-icon mr-2 rounded-circle waves-effect waves-themed">
                                                                        <i className="fal fa-file-pdf"></i>
                                                                    </button>

                                                                    <SweetButton props={{
                                                                        button: {
                                                                            className: "btn btn-danger btn-icon rounded-circle waves-effect waves-themed",
                                                                            icon: "fa-times",
                                                                            text: ""
                                                                        },
                                                                        swlConfig: {
                                                                            type: "dialog",
                                                                            icon: "question",
                                                                            title: "Deseja excluir",
                                                                            text: `Cód. ${el.ingresso_id} - ${el.client_name}?`,
                                                                            confirmButtonText: "Sim, excluir mesmo assim.",
                                                                            confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                        },
                                                                        actions: {
                                                                            IfConfirmed: () => handlerDelete(el.ingresso_id), // Passar como uma função
                                                                            IfDenied: ""
                                                                        }
                                                                    }} />
                                                                </td>
                                                            </tr>
                                                        )))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Cód. Ingresso</th>
                                                        <th>Nome do cliente</th>
                                                        <th>Cpf/Cnpj</th>
                                                        <th>E-mail</th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div >

        </div >

    );
}

export default Ingresso;
