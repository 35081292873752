import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle, useCallback } from 'react'
import { ErrorMessage, useFormik } from 'formik';
/* PRIME REACT */
import { Button } from 'primereact/button';
import { Paginator } from 'primereact/paginator';
import { Ripple } from 'primereact/ripple';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Slider } from 'primereact/slider';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';

/* COMPONENTES */
import Add from '../../components/Add'
import MyButton from '../../components/MyButton'
import SweetButton from '../../components/SweetButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import TabNavigation from '../../components/TabNavigation';
import CustomInputText from '../../components/CustomInputText';
import CustomDropdown from '../../components/CustomDropdown';
import CustomInputNumber from '../../components/CustomInputNumber';
import CustomInputMask from '../../components/CustomInputMask';
import CustomInputMonetary from '../../components/CustomInputMonetary';
import Alert from '../../components/Alert.jsx';
import { CustonSwal } from '../../components/Swal';

/* UTILITÁRIOS */
import Permission from '../../utils/Permission.js'
import SingHelper from '../../utils/SingHelper';
import SingMask from '../../utils/SingMask';
import ExportPDF from '../../utils/jspdf';

/* INCLUDES */
import schemaClass from '../../tables/colaborador'


import * as Yup from 'yup';
import axios from 'axios'

import { handlerSave, crudDelete } from '../../services/course/http'
/* ESTILOS CSS */
import 'primereact/resources/themes/lara-light-purple/theme.css';




const FragmentList = forwardRef((props, ref) => {
    //const navigate = useNavigate();

    /* VARIAVEIS */
    const parentCallback = props.parentCallback;

    const data = props.data;
    const loading = props.loading;
    const [getAction, setAction] = useState('create');
    const [getList, setList] = useState([])
    const [count, setCount] = useState(0);

    const [valor, setValor] = useState(0);
    const [parcela, setParcela] = useState(1);
    const [descricao, setDescricao] = useState('');
    const [getOrigin, setOrigin] = useState('ALL');
    const [getQueryParams, setQueryParams] = useState('');
    const [getFormData, setFormData] = useState();

    /* states de paginator */
    const [first, setFirst] = useState([0, 0, 0]);
    const [rows, setRows] = useState([10, 10, 10]);
    const [getPageCount, setPageCountOptions] = useState();
    const [getTotalRecords, setTotalRecords] = useState(0);

    const [currentPage, setCurrentPage] = useState(1);

    const [isShow_paginator, setShow_paginator] = useState(true);

    const [pageInputTooltip, setPageInputTooltip] = useState("Pressione 'Enter' para próxima página.");




    const show = () => {
        //toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };

    /* EXPORT PDF*/
    const generatePdf = async (el) => {
        let data = {
            "page": 2,
            "per_page": 6,
            "total": 12,
            "total_pages": 2,
            "download": true,
            "configs": {
                "template": "colaborador"
            },
            "content": {
                "colaborador": schemaClass.sanitize(el)
            },
            "support": {
                "url": "https://reqres.in/#support-heading",
                "text": "To keep ReqRes free, contributions towards server costs are appreciated!"
            },
            "qrcode": { "qrcodeOrigin": false }
        }
        await axios
            .post(`${process.env.REACT_APP_SERVER_PDF}/generateReport?download=true`, data, {
                responseType: "blob", // this is important!
                headers: { Authorization: "sometoken" },
            })
            .then(response => {
                //console.log("generatePdf", response);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
            })
            .catch(r => {
                console.log("Houve algum erro");
            })
        //http://localhost:4002/generateReport?download=true
    }
    const useForceUpdate = () => {
        const [getList, setList] = useState();
        return useCallback(() => setList({}), []);
    }

    const onPageChange = (e, index) => {
        e.page = e.page + 1;
        e.origin = getOrigin;
        e.q = getQueryParams;
        
        setFirst(first.map((f, i) => (index === i ? e.first : f)));
        setRows(rows.map((r, i) => (index === i ? e.rows : r)));
        props.callbackPaginator(e)
    };

    const onPageInputChange = (event, options) => {
        setPageCountOptions(options);

        setCurrentPage(event.target.value);
    };


    const onPageInputKeyDown = (event, options) => {
        
        if (event.key === 'Enter') {
            const page = parseInt(currentPage);

            if (page < 0 || page > options.totalPages) {
                setPageInputTooltip(`Valor de ser entre 1 e ${options.totalPages}.`);
            } else {
                let _first = [...first];

                _first[0] = currentPage ? options.rows * (page - 1) : 0;

                setFirst(_first);
                setPageInputTooltip("Pressione 'Enter' para próxima página.");
            }
        }
    };
    const handlerDelete = async (colaborador_id) => {
        let ok = await crudDelete(colaborador_id, { table: 'colaborador' })
        if (ok) {
            const newgetList = getList.filter((el) => el.colaborador_id !== colaborador_id);
            setList(newgetList);
        }
    }

    /* 
!!!!!!!! NÃO DELETAR ESSE CÓDIGO !!!!!!!!!
const leftContent = <Button type="button" icon="pi pi-star" className="p-button-outlined" />;
const rightContent = <Button type="button" onClick={handleBtnSearchPaginator} icon="pi pi-search" />; */
    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        PrevPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Anterior</span>
                    <Ripple />
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button type="button" className={classNames(options.className, 'border-round')} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Próxima</span>
                    <Ripple />
                </button>
            );
        },
        PageLinks: (options) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, { 'p-disabled': true });

                return (
                    <span className={className} style={{ userSelect: 'none' }}>
                        ...
                    </span>
                );
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple />
                </button>
            );
        },
        RowsPerPageDropdown: (options) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 'Todas', value: options.totalRecords }
            ];

            return <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />;
        },
        CurrentPageReport: (options) => {
            return (
                <>
                    <span className="mx-3" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
                        ir para <InputText size="2" className="ml-1" value={currentPage} tooltip={pageInputTooltip} onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={(e) => onPageInputChange(e, options)} />
                    </span>
                    <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                        {options.first} a {options.last} de {options.totalRecords}
                    </span>
                </>
            );
        }
    };







    useImperativeHandle(ref, () => ({
        refreshTableList: (args) => {

            setShow_paginator(args.show_paginator ?? true);
            setOrigin(args.origin ?? getOrigin);
            setQueryParams(args.q ?? getQueryParams);

            
            console.group('%c ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| HTML LIST :: useImperativeHandle :: refreshTableList |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~', 'color: #000; background-color: green;');
            
            if (args.action === "ADD") {
                console.log("::::::::: useImperativeHandle ::::::::: refreshTableList :: ADD");                
                setTotalRecords(args.result_row_count_total)
                setList(args.payload)                
            }

            if (args.action === "ALL" || args.action === "RELOAD") {
                console.log("::::::::: useImperativeHandle ::::::::: refreshTableList :: ALL");
                setTotalRecords(args.result_row_count_total)
                setList(args.payload)
            }

            if (args.action === "UPDATE") {
                console.log(":: useImperativeHandle :: refreshTableList :: UPDATE", args);
                //setList([...getList.filter((el) => el.colaborador_id !== args.key), args.payload])
                // Encontrar o índice do objeto no array com base na condição
                const indiceAlvo = getList.findIndex(el => el.colaborador_id === args.key);
console.log("indiceAlvo",indiceAlvo,args);
                // Verificar se o índice é válido
                if (indiceAlvo !== -1) {
                    // Clonar o array para evitar a mutação direta
                    const novogetList = [...getList];

                    // Atualizar o objeto no índice alvo com os novos dados
                    novogetList[indiceAlvo] = {
                        ...novogetList[indiceAlvo],
                        ...args.payload,
                    };

                    // Atualizar o array original
                    setTotalRecords(novogetList.length)
                    setList(novogetList);

                }
            }
            console.groupEnd();
        }
    }));

    useEffect(() => {
        console.log("HTMLLIST - useEffect getList/data: ", getList, data);
        setList(getList);
    }, [loading])

    useEffect(() => {
        console.log("TESTANDO USEEFFECT");
    })


    return (
        <div className="row">
            <div className="col-xl-12">

                <div id="panel-1" className="panel">

                    <div className="panel-hdr">
                        <h2>
                            Listagem
                        </h2>
                        <div className="panel-toolbar">
                            <button onClick={() => ExportPDF(getList)} className="btn btn-icon btn-sm btn-success shadow-0 rounded-circle waves-effect waves-themed">
                                <i className="fal fa-file-pdf"></i>
                            </button>
                        </div>
                    </div>
                    <div className="panel-container show">
                        <div className="panel-content">

                            {loading == false ?
                                <div className="d-flex justify-content-center">
                                    <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando...
                                    </button>
                                </div> : (
                                    getList.length == 0 ? <Alert text={<><strong>Sinto muito!!</strong> Nenhum registro encontrado</>} icon="" className="warning" iconAlign="left" /> : (

                                        <table id="dt-basic-example" className="table table-bordered table-hover table-striped w-100">
                                            <thead className="bg-primary-600">
                                                <tr>
                                                    <th>Cód. colaborador</th>
                                                    <th>Nome do colaborador</th>
                                                    <th>CPF</th>
                                                    <th>Data de nascimento</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    getList.map(el => (

                                                        <tr key={el.colaborador_id}>
                                                            <td>{el.colaborador_id}</td>
                                                            <td>{el.colaborador_name}</td>
                                                            <td>{SingMask.toDocument(el.cpf, "M")}</td>
                                                            <td>{SingMask.toDate(el.dt_nascimento, "dmY")}</td>
                                                            <td>{el.colaborador_status_id}</td>
                                                            <td>
                                                                <button onClick={() => parentCallback(el)} className="btn btn-primary btn-icon mr-2 rounded-circle waves-effect waves-themed">
                                                                    <i className="fal fa-edit"></i>
                                                                </button>
                                                                <button onClick={() => generatePdf(el)} className="btn btn-success btn-icon mr-2 rounded-circle waves-effect waves-themed">
                                                                    <i className="fal fa-file-pdf"></i>
                                                                </button>

                                                                <SweetButton props={{
                                                                    button: {
                                                                        className: "btn btn-danger btn-icon rounded-circle waves-effect waves-themed",
                                                                        icon: "fa-times",
                                                                        text: ""
                                                                    },
                                                                    swlConfig: {
                                                                        type: "dialog",
                                                                        icon: "question",
                                                                        title: "Deseja excluir",
                                                                        text: `Cód. ${el.colaborador_id} - ${el.colaborador_name}?`,
                                                                        confirmButtonText: "Sim, excluir mesmo assim.",
                                                                        confirmButtonColor: '#d33', // Altere para a cor desejada em hexadecimal
                                                                    },
                                                                    actions: {
                                                                        IfConfirmed: () => handlerDelete(el.colaborador_id), // Passar como uma função
                                                                        IfDenied: ""
                                                                    }
                                                                }} />
                                                            </td></tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th>Cód. colaborador</th>
                                                    <th>Nome do colaborador</th>
                                                    <th>CPF</th>
                                                    <th>Data de nascimento</th>
                                                    <th>Status</th>

                                                </tr>
                                                <tr>
                                                    <td colSpan={6}>
                                                        {isShow_paginator &&
                                                            <Paginator template={template1} first={first[0]} rows={rows[0]} totalRecords={getTotalRecords} onPageChange={(e) => onPageChange(e, 0)} /* leftContent={leftContent} */ /* rightContent={rightContent} */ />
                                                        }
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
})

export default FragmentList;