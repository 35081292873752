import React from "react";
//import "./spinner.css";

export default function LoadingSpinner() {
  return (
    <>
      {/* <div className="spinner-container">
      <div className="loading-spinner">
      </div>
    </div> */}
      <div className="d-flex justify-content-center mb-3">
        <button className="btn btn-primary rounded-pill waves-effect waves-themed" type="button" disabled="">
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span> &nbsp;Carregando...
        </button>
      </div >
    </>
  );
}