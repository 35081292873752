/* import logo from './logo.svg'; */


import Ingresso from './pages/Ingresso';
import Page from './pages/Page';
import Colaborador from './pages/Colaborador';
import Login from './pages/Login';
import Folha_pgto_item from './pages/Folha_pgto_item';
import Lancamentos from './pages/lancamentos';
//import Home from './pages/Home';
import { Route, Router, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/ingresso" element={<Ingresso />} />
        <Route path="/admin/register/folha_pgto" element={<Page />}>
          <Route exact path=":action" element={<Page />} />
          <Route path=":item/:action" element={<Page />} />          
        </Route>
        <Route path="/admin/register/folha_pgto_item" element={<Folha_pgto_item />}>
          <Route exact path=":action" element={<Folha_pgto_item />} />
          <Route path=":item/:action" element={<Folha_pgto_item />} />          
        </Route>
        <Route path="/admin/register/lancamentos" element={<Lancamentos />}>
          <Route exact path=":action" element={<Lancamentos />} />
          <Route path=":item/:action" element={<Lancamentos />} />          
        </Route>
        <Route path="/admin/register/colaborador" element={<Colaborador />}>
          <Route exact path=":action" element={<Colaborador />} />
          <Route path=":item/:action" element={<Colaborador />} />          
        </Route>
        <Route path="/admin" element={<Page />} />
        <Route path="*" element={<Login />} />
      </Routes>

    </div>
  );
}

export default App;
