import React from 'react';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';

const CustomInputMonetary = ({ field, label, readonly, args, formik, value, onValueChange2, element }) => {
//console.log("$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$",value);
    const handleFieldChange = (e) => {
        //console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",element,e);
        //console.log("value",value);
        if (formik)
            formik.setFieldValue(field, e.value);
        else
        {
            element[field]=e.value;
            onValueChange2(e)
        }
    };

    const configs = {
        mode: 'currency',
        currency: 'BRL',
        locale: 'pt-br',
    };
    const options = args && args.currency
        ? {
            mode: args.mode,
            currency: args.currency,
            locale: args.locale,
        }
        : configs;
    let _classNames = formik ? classNames({ 'p-invalid': formik.touched[field] && formik.errors[field] }) : "";

    return (
        <>
            {
        label ? <label htmlFor={field}>{label}</label>:''
    }

            <InputNumber
                id={field}
                value={args?.editable ? value : formik.values[field]}
                className={`form-control ${_classNames}`}
                name={field}
                readOnly={readonly || args?.action === "view" ? true : false}
                onBlur={formik ? formik.handleBlur : ""}                
                onValueChange={(e) => handleFieldChange(e)}
                {...options}
            />
            {formik && formik.touched[field] && formik.errors[field] && <div className='formik-error'>{formik.errors[field]}</div>}

        </>
    );
};

export default CustomInputMonetary;
