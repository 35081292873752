import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import { Link, useParams, useNavigate } from 'react-router-dom'


/* COMPONENTES */
import Add from '../../components/Add'
import MyButton from '../../components/MyButton'
import SweetButton from '../../components/SweetButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import TabNavigation from '../../components/TabNavigation';
import CustomInputText from '../../components/CustomInputText';
import CustomDropdown from '../../components/CustomDropdown';
import CustomInputNumber from '../../components/CustomInputNumber';
import CustomInputMask from '../../components/CustomInputMask';
import CustomInputMonetary from '../../components/CustomInputMonetary';
import { CustonSwal } from '../../components/Swal';
/* NOVO */
import { MultiSelect } from 'primereact/multiselect';

/* FIM - NOVO */
/* UTILITÁRIOS */
import Permission from '../../utils/Permission.js'
import SingHelper from '../../utils/SingHelper';

import * as Yup from 'yup';
import axios from 'axios'

import schemaClass from '../../tables/folha_pgto_item'
import MyYup from '../../utils/MyYup';
import Alert from '../../components/Alert.jsx';

const myYup = new MyYup({ teste: "teste" });
myYup.setSchema(schemaClass);


const FragmentForm = forwardRef((props, ref) => {
    const navigate = useNavigate();

    const getHeaders = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
            headers: {
                "Token-Authorization": user.USER_TOKEN,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br"
            }
        };
    }
    /* VARIAVEIS */
    const parentCallback = props.parentCallback;
    const initialValues = {
        "folha_pgto_item_id": "",
        "folha_pgto_id": "",
        "valor": "",
        "colaborador_id": "",
        "folha_pgto_item_status_id": "",
        "_created_at": "",
        "_created_user_id": "",
        "_updated_user_id": "",
        "_updated_at": ""
    };
    const validateSchema = Yup.object().shape({
        client_name: Yup.string().required("Campo obrigatório"),
        cpf: Yup.string().required("Campo obrigatório"),
        email: Yup.string().required("Campo obrigatório"),
        telefone: Yup.string().required("Campo obrigatório")
        /* student_certificate_config_id: Yup.string().required("Campo obrigatório"),
        icp_certificate_config_id: Yup.string().required("Campo obrigatório"),
        city_id: Yup.string().required("Campo obrigatório"),
        workload: Yup.number().required("Campo obrigatório"),
        vacancy: Yup.number().min(1, "Valor deve ser maior ou igual a um").required("Campo obrigatório"),
        class_status_id: Yup.string().when(getAction, {
            is: 'patch',
            then: (validateSchema) => validateSchema.required('Campo obrigatório'),
            otherwise: (schema) => schema.min(0)
        }),
        price_cash: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Valor informado é inválido!').required("Campo obrigatório"),
        price_split: Yup.string().matches(/^\d+(\.\d{1,2})?$/, 'Valor informado é inválido!').required("Campo obrigatório"),
        dt_start: myYup.date().exe(),
        dt_end: myYup.date().moreThan("dt_start").exe(),
        class_modality_id: Yup.string().required("Campo obrigatório") */
    });

    const [getAction, setAction] = useState('post');
    const [getModeForm, setModeForm] = useState(props.mode);
    
    /* NOVOS */
    const [selectedCities, setSelectedCities] = useState([]);
    const cities2 = [
        { code: "122", name: "ABDORAL PEREIRA DA SILVA" },
        { code: "142", name: "ADRIANO DA CONCEIÇÃO" },
        { code: "151", name: "ADRYELLE RODRIGUES CAVALCANTE" },
        { code: "148", name: "AIRTON CARLOS GONÇALVES DE ALENCAR" },
        { code: "83", name: "ALEX FERREIRA COSTA" },
        { code: "75", name: "ÁLISON DE SOUSA BRITO" },
        { code: "118", name: "ALOM SILVA DE SOUSA" },
        { code: "106", name: "ANTÔNIO COSTA DE OLIVEIRA FILHO" },
        { code: "112", name: "ANTONIO DA SILVA SANTOS" },
        { code: "121", name: "ANTONIO JOSE FERREIRA CAMPOS" },
        { code: "91", name: "ARIÁDINA CRISTINA LIMA LIRA" },
        { code: "110", name: "ATHOS VINICIUS SOARES PEREIRA" },
        { code: "144", name: "BENEDITO BARBOSA DOS SANTOS FILHO" },
        { code: "139", name: "CARLOS EDUARDO SILVA E SILVA" },
        { code: "97", name: "CLAUDEAN SOARES DE OLIVEIRA" },
        { code: "137", name: "CLEODETE FERREIRA MENDES" },
        { code: "109", name: "CRHISTIAN SLATER FURTADO BEZERRA" },
        { code: "152", name: "DARLAN SANTOS PINHEIRO" },
        { code: "85", name: "DIEGO DA SILVA NEVES" },
        { code: "84", name: "DORGIVAL LOPES DOS SANTOS" },
        { code: "94", name: "EDIVAN DOS SATOS CRUZ" },
        { code: "155", name: "ELDISCLEI PONTES DE SOUSA" },
        { code: "107", name: "ELENICE ARAUJO ALENCAR" },
        { code: "158", name: "ELIANE DO NASCIMENTO SILVA" },
        { code: "103", name: "ERISVALDO ARAUJO DOS SANTOS" },
        { code: "79", name: "ERNANDES MARIANO DE ARAUJO" },
        { code: "114", name: "FÁBIO OLIVEIRA COSTA" },
        { code: "102", name: "FRANCISCO CARLOS FERNANDES DE LIMA" },
        { code: "93", name: "FRANCISCO DAS CHAGAS DOS SANTOS CRUZ" },
        { code: "87", name: "FRANCISCO DE ASSIS VIEIRA DOS SANTOS FILHO" },
        { code: "138", name: "FRANCISCO DIAS DOS SANTOS" },
        { code: "150", name:"FRANCISCO ÉRISON SANTOS BATISTA"},
        { code: "101", name: "FRANCISCO FREITAS LIMA" },
        { code: "92", name: "FRANCISCO LEONARDO DA SILVA" },
        { code: "119", name: "FRANCISCO LUCIANO DE SOUSA LIMA" },        
        { code: "126", name:"FRANCISCO MANOEL OLIVEIRA"},
        { code: "149", name:"FRANCISCO PEREIRA DO NASCIMENTO CRUZ"},
        { code: "105", name: "GEIFFERSON GOMES MORAISDO NASCIMENTO" },
        { code: "120", name: "GISLEY NUNES DA SILVA" },
        { code: "100", name: "HALFE MANOEL GALVÃO BRANDIM" },
        { code: "153", name: "ITAILSON SOARES DE MOURA" },
        { code: "116", name: "IVO HENNRRIQUE SANTOS DE OLIVEIRA" },
        { code: "81", name: "JAIRO ANTONIO SOUSA DA SILVA" },
        { code: "156", name: "JAISSON SOUSA DOS SANTOS" },
        { code: "73", name: "JEAN CARLOS DA SILVA COSTA" },
        { code: "147", name: "JEAN KELSON FERREIRA DA SILVA" },
        { code: "136", name: "JEFERSON RODRIGUES RAMOS" },
        { code: "77", name: "JOÃO DUARTE DA SILVA" },
        { code: "76", name: "JOÃO HENRIK DA CRUZ SILVA" },
        { code: "78", name: "JOÃO PAULO DA SILVA SANTOS" },
        { code: "104", name: "JOAO PEREIRA DE SOUSA" },
        { code: "98", name: "JOSE ALVES DA SILVA" },
        { code: "89", name: "JOSE CARLOS DA SILVA SANTOS" },
        { code: "133",	name:"JOSE CARLOS QUARESMA TORRES"},
        { code: "115", name: "JOSÉ DA SILVA ARAUJO" },
        { code: "159", name: "JOSE HENRIQUE GUERRA DE SOUSA" },
        { code: "99", name: "JOSÉ NILTON DA COSTA SANTOS" },
        { code: "130", name: "JOSÉ LUÍZ DA SILVA AZEVEDO" },
        { code: "145", name: "JOSE WALBER DE OLIVEIRA RIBEIRO" },
        { code: "88", name: "JOSE WALLACE CARVALHO SILVA" },
        { code: "157", name: "LEONARDO DA SILVA GOMES" },
        { code: "140", name: "LIDOMAR ALVES DA SILVA" },
        { code: "135", name: "LUIS CARLOS DO NASCIMENTO" },
        { code: "82", name: "LUIZ HENRIQUE MORAIS SANTOS" },
        { code: "117", name: "MANOEL MESSIAS LEITE DA SILVA" },
        { code: "86", name: "MARCELO FELIX DA SOUSA" },
        { code: "96", name: "MARCIO JOSÉ DA SILVA RODRIGUES" },
        { code: "161", name: "MARIA DAS  DORES ALVES GOMES" },
        { code: "108", name: "MATHEUS DA SILVA SANTOS" },
        { code: "124", name: "MESSIAS RODRIGUES SILVA" },
        { code: "131", name: "MIGUEL ALAN DIAS DA SILVA" },
        { code: "95", name: "NAELTON DE SOUSA BRITO" },
        { code: "132", name: "NATALINE MENDES SOUSA" },
        { code: "127", name:"NATANE FERREIRA MENDES"},
        { code: "90", name: "NAIARA EVÉLIN BARROS OLIVEIRA" },
        { code: "113", name: "OSENIR MIRANDA BARBOSA" },
        { code: "160", name: "PAULO HENRIQUE DE OLIVEIRA FERREIRA" },
        { code: "146", name: "PEDRO LUCAS DA SILVA CARVALHO" },
        { code: "143", name: "RAFAEL GOMES DE MACEDO" },
        { code: "134",	name:"REINALDO OLIVEIRA DA SILVA"},
        { code: "74", name: "RICARDO ARAUJO DOS SANTOS" },
        { code: "125", name: "RICARDO SOUSA RAMOS" },
        { code: "154", name: "RODDRIGO PRUDENCIO ROCHA" },
        { code: "123", name: "SAMIO CASSIO RODRIGUES DE MACEDO" },
        { code: "111", name: "STERNIO DE SOUSA EULALIO MARTINS FILHO" },
        { code: "80", name: "TAMYRES DA CONCEIÇÃO FERREIRA" },
        { code: "72", name: "TATIANA DIAS DA SILVA" },
        { code: "141", name: "VAGNO CAMPELO DA SILVA" },
        { code: "128", name:"VITÓRIA BENVINDO"}
    ];	
    	
    	

    /* FIM - NOVOS */
    /* COMPONENTES PRIME-REACT */

    const [isActiveLoader, activeLoader] = useState(false)

    /* USE STATE QUE CONTROLA AS ABAS */
    const [getList, setList] = useState([])
    const [cities, setCities] = useState([])
    const [getTbInit, setTbInit] = useState([])
    const [getFormData, setFormData] = useState(initialValues);

    const [swalProps, setSwalProps] = useState({});
    const [activeButton, setActiveButton] = useState("");
    const [disabledSubmit, setDisabledSubmit] = useState("")    //NEW

    const [activeBtnCancel, setActiveBtnCancel] = useState(false);
    const [activeBtnSave, setActiveBtnSave] = useState(false);
    const [activeBtnPatch, setActiveBtnPatch] = useState(false);
    const [onListener, setListener] = useState(false)    //NEW

    const toast = useRef(null);
    const MyButtonSave = useRef();
    const MyButtonCancel = useRef();
    const MyButtonNew = useRef();
    const copyLink = useRef("");

    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };

    /* FUNÇÕES PRINCIPAIS*/
    /* ESSA FUNÇÃO É RESPONSÁVEL POR FAZER O RESET NO FORMIK */
    const handleClickCancel = (name) => {
        activeLoader(true);
        setActiveButton(name)
        setModeForm("post");
        formik.resetForm();
    };


    /* FUNÇÃO RESPONSÁVEL PELA EDIÇÃO, POPULANDO O PAYLOAD NO FORMIK */
    useImperativeHandle(ref, () => ({
        populaForm: (param) => {
        
            setFormData(schemaClass.sanitize(param))
            setModeForm(props.mode)
            navigate(`/admin/register/folha_pgto_item/${param.folha_pgto_item_id}/patch`);
        },
        saveItem: (el, i) => {
            var query = window.location.href.split("/");

            //var qs = parse_query_string(query);
            //let name = params.get("name"); // is the string "Jonathan"
            console.log('%c ----------------------------------------- PÁGINA FORM - [saveItem] -----------------------------------------', 'color: #000; background-color: pink;');
            console.log(el, i);

            const _id = el.folha_pgto_item_id >= 1 ? `/${el.folha_pgto_item_id}` : "";
            
            el.folha_pgto_id = query[6];
            const instance = axios.create({
                baseURL: process.env.REACT_APP_HOST_API_CLIENT,
                timeout: 5000,
                headers: getHeaders().headers
            });

            const getModeForm = el.folha_pgto_item_id >= 1 ? "patch" : "post";

            // Dados que você deseja enviar ou atualizar (depende do verbo)
            const data = schemaClass.beforeSend(el);
            // URL do endpoint
            const url = `/api/v1/folha_pgto_item${_id}`; // Observe que você pode usar caminhos relativos com a instância
            
            // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
            instance[getModeForm](url, data)
                .then((response) => {
                    console.log(":: useImperativeHandle - RESPONSE :: "+getModeForm, response);
                    formik.resetForm()
                    if (response.status === 201) {
                        let res = response.data;
                        CustonSwal(res.result_code);
                        activeLoader(false);

                        if (getModeForm === "post") {
                            el.folha_pgto_item_id = res.result;
                            parentCallback({ "key": res.result, "payload": el, "action": "ADD" });
                            formik.resetForm();
                        }
                        if (getModeForm === "patch") {
                            setModeForm("post")                            
                            parentCallback({ "key": data.folha_pgto_item_id, "payload": el, "action": "UPDATE" });
                            formik.resetForm();
                        }
                    }                    
                })
                .catch((error) => {
                    console.error(`Erro na solicitação ${getModeForm.toUpperCase()}:`, error);
                    CustonSwal(error.response.data.resume.failure.fault.fault_code);
                    activeLoader(false);
                });
        }
    }));

    const formik = useFormik({
        initialValues,
        validationSchema: validateSchema,
        onSubmit: (payload) => {           
        },
        validateOnBlur: false, // Definir validateOnBlur como true
    });

    const getListBd = async (args) => {
        console.clear();
        
        console.log("ARGS RECEBIDOS: ", args);
        let page =  1;
        let limit = 310;
        let sort =  'colaborador_name+asc';

        let count_all_rows = false;
        let show_paginator = false;

        let origin = `&search=true`;        

        let url = `${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/colaborador?count_all_rows=${count_all_rows}&limit=${limit}&page=${page}&sort=${sort}${origin}`;
                
        //activeLoadingDiv(true);
        await axios
            .get(url, getHeaders())
            .then(response => {

                console.log("response",response.data.result);
                if (response.data.status == 200) {
                    //console.log("~~~~ CARREGANDO COLABORADOR ~~~~", response.data);
                    //activeLoadingDiv(false);
                    //setShowLoader(true)
                    let res = response.data
                    let payload = { payload: res.result, result_row_count_total: res.result_row_count_total, show_paginator: show_paginator, action: "ALL" };
                    const cities2 = response.data.result.map(colaborador => ({
                        code: colaborador.colaborador_id.toString(),
                        name: colaborador.colaborador_name
                    }));
                    setCities(cities2)
                    //refreshList(payload);
                }
            })
            .catch(r => {
                
                console.log("r.response.data.result_code", r,r.response.data.resume.failure.fault.fault_code);
                CustonSwal(r.response.data.resume.failure.fault.fault_code);

            })
        
    }


    useEffect(() => {
        
        
        (async () => {
            
            getListBd(null);
            let headers = getHeaders();
            headers.params = {
                tables: [
                    /* {
                        table: "tb_course",
                        asc: "course_name",
                        where: "course_"
                    }, */
                    {
                        table: "tb_regedit"
                    }/* ,
                        {
                            table: "tb_class_modality"
                        },
                        {
                            table: "tb_city"
                        } */
                ]
            };
            await axios
                .get(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/regedit`, headers)
                .then(response => {
                    //console.clear();              
                    if (response.status == 200) {
                        console.log("`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/regedit` :: response", response.data);
                        let responseData = response.data;
                        setTbInit(responseData);
                        console.log("getTbInit", getTbInit);
                    }
                })
                .catch(r => {
                    console.log("~~~~~~~~~~~~~ | catch", r);
                })
        })();
    }, []);

    useEffect(() => {
        setModeForm(getModeForm)
        activeLoader(false);
        formik.setValues(getFormData);
    }, [getFormData])

    useEffect(() => {
        console.log("selectedCities", selectedCities);
        
        setDisabledSubmit(selectedCities.length == 0 ? "disabled" : "")
    }, [selectedCities])
    
    /* NOVOS */
    /* FUNÇÕES PRINCIPAIS*/
    const loadConfigCertificate = async () => {
        let headers = getHeaders();
        headers.params = {
            tables: [
                {
                    table: "tb_status"
                }
            ]
        };
        await axios
            .get(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/regedit`, headers)
            .then(response => {
                console.log("GET: BD GET CONFIGURAÇÃO CERTIFICADO", response.data);
                if (response.status == 200) {
                    let responseData = response.data['tb_certificate_config'];
                    setTbInit((prevData) => ({ ...prevData, responseData }));
                    setListener(responseData);
                }
            })
            .catch(r => {

            })
    }
    /* FIM - NOVOS */
    return (
        <div id="panel-2" className="panel">
            <div className="panel-hdr">
                {<h2>
                    Cadastro {getModeForm}
                </h2>}
                <div className="panel-toolbar">
                    { /*<button className="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                        <button className="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button> */}
                    <button className="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                </div>
            </div>
            <div className="panel-container show">
                {
                    getModeForm == "patch" && getFormData.length == 0 ?
                        <div className="panel-content">
                            <Alert text={<><strong>Sinto muito!!</strong> Não foi enviado uma informação para edição</>} icon="" className="danger" iconAlign="left" />
                        </div> : (
                            <>
                                {<div className="panel-content">
                                    {/*<div className="panel-tag">
                                            <p>Aqui vai uma descrição opcional<code>Code</code>. Continua descrição.</p>
                                            <p>Subdescrição opcional</p>
                                        </div>*/}
                                </div>}
                                <div className="panel-content p-0">
                                    {getTbInit.length === 0 ? <LoadingSpinner /> :
                                        <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation needs-validation" novalidate id="form-create-update">

                                            <div className="panel-content">
                                                <div className="form-row">
                                                    {/*--- INÍCIO ---*/}

                                                    <div className="col-md-6 mb-3">
                                                        <CustomDropdown
                                                            field="colaborador_idwww"
                                                            label="Tipo de vínculo"
                                                            options={[getTbInit, 'tb_centro_custo', 'centro_custo_name', 'centro_custo_id']}
                                                            labelField="name"
                                                            listener={onListener}
                                                            onBlurHandler={() => loadConfigCertificate()}
                                                            placeholder=""
                                                            formik={formik}
                                                        />

                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomDropdown
                                                            field="colaborador_id"
                                                            label="Tipo de vínculo"
                                                            options={[getTbInit, 'tb_status', 'status_name', 'status_id']}
                                                            labelField="name"
                                                            listener={onListener}
                                                            //onBlurHandler={() => loadConfigCertificate()}
                                                            placeholder=""
                                                            formik={formik}
                                                        />

                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <label>Colaboradores</label>
                                                        <MultiSelect value={selectedCities} onChange={(e) => setSelectedCities(e.value)} options={cities} optionLabel="name"
                                                            placeholder="Selecione os colaboradores" maxSelectedLabels={3} className="form-control" />
                                                    </div>
                                                    {/* <div className="col-md-6 mb-3">
                                                        <MyButton
                                                            name="btnCancel"
                                                            text="Inserir 2"
                                                            type="button"
                                                            loading={isActiveLoader}
                                                            disabled={isActiveLoader}
                                                            activeButton={activeButton}
                                                            onClick={(el) => parentCallback({ "key": 1, "payload": selectedCities, "action": "ADD" })}
                                                            icon="fal fa-times"
                                                            btnClass="btn btn-default ml-auto" />
                                                    </div> */}

                                                    {/*--- FIM ---*/}
                                                </div>
                                            </div>

                                            <div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row   bg-faded">
                                                {/* <div className="custom-control custom-checkbox flex-grow-1">
                <input type="checkbox" className="custom-control-input" id="invalidCheck" required />
                <label className="custom-control-label" for="invalidCheck">Cliente ciente dos termos? <span className="text-danger">*</span></label>
                <div className="invalid-feedback">
                    Você deve selecionar o seguinte campo
                </div>
            </div> */}

                                                {
                                                    getModeForm == "patch" &&
                                                    <MyButton
                                                        name="btnCancel"
                                                        text="Cancelar"
                                                        type="button"
                                                        loading={isActiveLoader}
                                                        disabled={isActiveLoader}
                                                        activeButton={activeButton}
                                                        onClick={(el) => handleClickCancel("btnCancel")}
                                                        icon="fal fa-times"
                                                        btnClass="btn btn-default ml-auto" />
                                                }
                                                {
                                                    getModeForm == "patch" &&
                                                    <MyButton
                                                        name="btnPatch"
                                                        text="Novo"
                                                        type="button"
                                                        loading={isActiveLoader}
                                                        disabled={isActiveLoader}
                                                        activeButton={activeButton}
                                                        onClick={(el) => handleClickCancel("btnPatch")}
                                                        icon="fal fa-plus"
                                                        btnClass="btn btn-success ml-2" />
                                                }
                                                <MyButton
                                                    name="btnSave"
                                                    text="Salvar"
                                                    type="submit"
                                                    loading={isActiveLoader}
                                                    disabled={disabledSubmit}
                                                    activeButton={activeButton}
                                                    onClick={(el) => parentCallback({ "key": 1, "payload": selectedCities, "action": "ADD",'insert_colaborador':true })}
                                                    icon="fal fa-save"
                                                    btnClass="btn btn-primary ml-2" />

                                            </div>

                                        </form>
                                    }
                                </div>
                            </>
                        )
                }

            </div>
        </div>
    );
})

export default FragmentForm;