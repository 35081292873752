import {useNavigate } from 'react-router-dom'

import Swal from 'sweetalert2';

const codSwal = {
    102: { icon: 'warning', title: 'Registro duplicado', text: 'Essa informação já existe no banco de dados!', confirmButtonText: 'OK' },
    103: { icon: 'warning', title: 'Registro duplicado', text: 'Essa informação já existe no banco de dados!', confirmButtonText: 'OK' },
    113: { icon: 'warning', title: 'Atenção', text: 'Alguma informação enviada não está dentro dos padrões aceitos.', confirmButtonText: 'OK' },
    201: { icon: 'success', title: 'Sucesso!', text: 'Salvo com sucesso.', confirmButtonText: 'OK' },
    202: { icon: 'success', title: 'Sucesso!', text: 'Criado com sucesso.', confirmButtonText: 'OK' },
    402: { icon: 'error', title: 'Autenticação', text: 'Sua sessão está expirada. Faça login novamente.', confirmButtonText: 'OK' },
    502: { icon: 'warning', title: 'Erro inesperado', text: 'Houve um erro não identificado.', confirmButtonText: 'OK' },
    503: { icon: 'warning', title: 'Falha!', text: 'Não foi possível processar a requisição..', confirmButtonText: 'OK' },
    4030: { icon: 'error', title: 'Negado!', text: 'Não foi possivel fazer a atualização.', confirmButtonText: 'OK' },
    4031: { icon: 'success', title: 'Sucesso!', text: 'Registro atualizado com sucesso.', confirmButtonText: 'OK' },
    40303: { icon: 'warning', title: 'Falha!', text: 'Não foi possível possível encontrar a informação solicitada.', confirmButtonText: 'OK' },
    40305: { icon: 'error', title: 'Atenção', text: 'Não foi possível possível encontrar a informação solicitada.', confirmButtonText: 'OK' },
    40306: { icon: 'error', title: 'Atenção', text: 'Você não possui status necessários para o continuar.', confirmButtonText: 'OK' },
    40307: { icon: 'error', title: 'Atenção', text: 'Você não possui status necessários para o continuar.', confirmButtonText: 'OK' },
    40333: { icon: 'success', title: 'Sucesso!', text: 'Não foi possivel criar ~~~~ TESTE ', confirmButtonText: 'OK' },
    50101: { icon: 'success', title: 'Título', text: 'Texto', confirmButtonText: 'OK' },
    4020: { icon: 'warning', title: 'Não localizado', text: 'Não foi possivel localizar esse CEP.', confirmButtonText: 'OK' },
    1000: { icon: 'warning', title: 'Busca vazia', text: 'Você deve enviar alguma informação.', confirmButtonText: 'OK' },
};

// Função para exibir um alerta de sucesso com base no código de status
export const CustonSwal = (success_code, msg,args=[]) => {
    /* const navigate = useNavigate(); */

    const swalData = codSwal[success_code]; // Acesse as informações com base no código de status
    if(success_code == 402){
        /* 
        navigate(`/admin/login`); */       
    }

    if (swalData) {
        Swal.fire({
            icon: swalData.icon,
            title: swalData.title,
            text: msg ?? swalData.text,
            confirmButtonText: swalData.confirmButtonText,
        });
    } else {

        console.error('Código de status não encontrado:', success_code);
        Swal.fire({
            icon: "error",
            title: "Erro inesperado",
            text: `Código [${success_code}] de status não encontrado!`,
            confirmButtonText: "OK",
        });
    }
};
