const schemaColaborador = {
    columns: {
        colaborador_id: {
            sendToDb:false,         
            onUpdate: "require",
        },
        colaborador_name: {

        },
        cpf: {

        },
        filiacao_01_name: {

        },
        filiacao_02_name: {

        },
        ctps: {

        },
        sexo: {

        },
        naturalidade: {

        },
        uf: {

        },
        estado_civil_id: {

        },
        instrucao: {

        },
        doc_rg: {

        },
        
        doc_rg_orgao_emissor: {

        },
        doc_cnh: {

        },
        email: {

        },
        forma_salario: {

        },
        dt_admissao: {
            type: "date",   
            
            converter: (my_date) => {
                
                if (my_date.indexOf("-")) {
                    const [year, month, day] = my_date.split('-');
                    return `${day}/${month}/${year}`;
                } else {
                    const [day, month, year] = my_date.split('/');
                    return `${year}-${month}-${day}`;
                }
            }
        },
        doc_rg_emissao: {
            type: "date",            
            converter: (my_date) => {
                
                if (my_date.indexOf("-")) {
                    const [year, month, day] = my_date.split('-');
                    return `${day}/${month}/${year}`;
                } else {
                    const [day, month, year] = my_date.split('/');
                    return `${year}-${month}-${day}`;
                }
            }
        },
        _created_at: {
            type: "date",   
            sendToDb:false,         
            converter: (my_date) => {
                
                if (my_date.indexOf("-")) {
                    const [year, month, day] = my_date.split('-');
                    return `${day}/${month}/${year}`;
                } else {
                    const [day, month, year] = my_date.split('/');
                    return `${year}-${month}-${day}`;
                }
            }
        },
        dt_nascimento: {
            type: "date",
            converter: (my_date) => {
                
                if (my_date.indexOf("-")) {
                    const [year, month, day] = my_date.split('-');
                    return `${day}/${month}/${year}`;
                } else {
                    const [day, month, year] = my_date.split('/');
                    return `${year}-${month}-${day}`;
                }
            }
        }
        
    },
    sanitize: function (data) {
        const convertedData = {};
        
        for (const key in data) {
            //let myData = data[key] === null ? "" : data[key]
            let myData = data[key] === null || data[key] === "" ? "" : data[key]
            if (this.columns[key] && this.columns[key].converter) {                
                convertedData[key] = this.columns[key].converter(myData);
            } else {
                
                convertedData[key] = myData;
            }
        }
        
        return convertedData;
    },
    beforeSend: function (data) {
        const convertedData = {};
        
        for (const key in this.columns) {
            let myData = data[key] === null || data[key] === "" ? "" : data[key]

            if (data.hasOwnProperty(key)) {
                
                if (!this.columns[key].hasOwnProperty('beforeSend')) {
                    if(!this.columns[key].hasOwnProperty('sendToDb')){
                        convertedData[key] = data[key];
                    }
                }
            }

            /* if (data[key] && this.columns[key].converter) {
                
                convertedData[key] = data[key].converter(myData);
            } else {
                convertedData[key] = myData;
            }  */

            //let myData = data[key] === null ? "" : data[key]
            /* let myData = data[key] === null || data[key] === "" ? "" : data[key]
            */
        }
        
        return convertedData;
    },
    getPrimaryKey:(obj)=>{
        
        return obj.colaborador_id
    }
};

export default schemaColaborador;
//ok    2