import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API
});

export const useApi = () => ({
    validateToken: async (token) => {
        return {
            user: {
                userName: "CESAR NEGREIROS",
                userNameLabel: "NEGREIROS",
                userLevelName: "Operador",
                user_id: "401",
                userToken: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6Ik1hcmNlbG8gTmVncmVpcm9zIiwidXNlcl9pZCI6IjQwMSIsImlhdCI6MTY4Mjc3OTQ4NH0.DrNB_XQ6NQ0j0C-k1JAxvexZEphEpfSILbGFbBhxxM0"
            }
        };
        const response = await api.post('/validate', { token });
        return response.data;
    },/* teste ok */
    signin: async (args) => {      
        console.log("Função SING-IN chamada:: ",process.env.REACT_APP_HOST_AUTH);
        let param = args;
        
        if(args.token)
        param = {token:args.token};
        
        const response = await api.post(`${process.env.REACT_APP_HOST_AUTH}/v1/user/login`, param, {
            headers: {
                "Token-Authorization": 'none',
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br",
                'Content-Type': 'application/json'
            }
        }).then(r => {
            console.log("Resposta api :: signin", r);
            const u = r.data.result[0];
            const resultSet = {
                user: {
                    USER_NAME: u.USER_NAME,
                    USER_IMG: u.USER_IMG,
                    USER_SHORT_NAME: u.USER_SHORT_NAME,
                    USER_LEVEL_NAME: u.USER_LEVEL_NAME,
                    USER_ID: u.USER_ID,
                    USER_TOKEN: u.USER_TOKEN,
                },
                token: u.USER_TOKEN,
                session: {
                    CURRENT_CLIENT_ID: u.CURRENT_CLIENT_ID

                },
                permission:u.PERMISSION
            }
            return { success: true, status: r.data.status, result: resultSet };
        }).catch(r => {
            console.log("Catch useApi.js", r);
            return { success: false, status: r.response.data.status, resume: r.response.data.resume };
        });

        return response;
    },
    logout: async () => {
        return { status: true };
        const response = await api.post('/logout');
        return response.data;
    }
});