import "jspdf-autotable";
import jsPDF from "jspdf";
import SingMask from './SingMask';
const exportPDF = (getList) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Listagem de colaboradores";
    const headers = [["Cód.", "Nome do colaborador", "Cpf/Cnpj", "E-mail", "Data nascimento","Ctps"]];

    const myFilter = getList.map(elt => [elt.colaborador_id, elt.colaborador_name, SingMask.toDocument(elt.cpf), elt.email, SingMask.toDate(elt.dt_nascimento),elt.ctps]);

    let content = {
        startY: 50,
        head: headers,
        body: myFilter
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
}
export default exportPDF;