import * as Yup from 'yup';

const isLeapYear = year => (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

const daysInMonth = (month, year) => {
  if (month === 2 && isLeapYear(year)) {
    return 29;
  }
  const days = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return days[month];
};


console.clear();

class MyYup {
  constructor(params) {
    this.action = "";
    this.schema = {};
    this.yup = Yup.string();    
    this.validDate = false; // Variável para controlar a validação do método date()

    //console.log("~~~~~~~~~MyYup params", params);
    this.params = params;
  }
  setAction(action) {
    //console.log("Atualizando action em myyup", action);
    this.action = action;
  }
  setSchema(schema) {
    this.schema = schema;
  }

  select() {
    //console.log("CHAMOU SELECT");
    return Yup.string().required('Data é obrigatória!');
  }
  date() {
    //console.log("REQ");
    this.yup = this.yup.matches(
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
      'Data inválida (dd/mm/YYYY)'
    )
      .test('valid-date', 'Data inválida', (value) => {
        if(value){

        
        const testtt = value.match(/^(\d{2})\/(\d{2})\/(20\d{2})$/);
        

        if (testtt) {
          const [, day, month, year] = testtt;
          const numericDay = parseInt(day, 10);
          const numericMonth = parseInt(month, 10);
          const numericYear = parseInt(year, 10);
          this.validDate = numericDay <= daysInMonth(numericMonth, numericYear);
            return this.validDate;
        }
      }
      });
    return this;
  }
  moreThan(target) {
    if (this.validDate) { // Verifica se a validação do método date() é true

    this.yup = this.yup.when(target, (dataInicial, schema) => {
      return schema.test({
        test: function (dataFinal) {
          
          if (dataFinal && dataInicial[0]) {
            const [startDay, startMonth, startYear] = dataInicial[0].split('/');
            const [endDay, endMonth, endYear] = dataFinal.split('/');

            const startDate = new Date(`${startYear}-${startMonth}-${startDay}`);
            const endDate = new Date(`${endYear}-${endMonth}-${endDay}`);
            return endDate >= startDate;
          }
          return true;
        },
        message: 'A data final deve ser maior que a data inicial',
      });
    })
  }
    return this;
  }

  exe() {
    const fim = this.yup;
    this.yup = Yup.string();
    return fim;
  }

  dateBK() {
    return Yup.string()
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/,
        'Data inválida (dd/mm/YYYY)'
      )
      .test('valid-date', 'Data inválida', value => {
        
        const testtt = value.match(/^(\d{2})\/(\d{2})\/(20\d{2})$/);
        if (testtt) {
          const [, day, month, year] = testtt;

          const numericDay = parseInt(day, 10);
          const numericMonth = parseInt(month, 10);
          const numericYear = parseInt(year, 10);

          return numericDay <= daysInMonth(numericMonth, numericYear);
        }
      })
      .required('Data é obrigatória!');
  }
};

//export default MyYup;
export default MyYup; // Exporta a classe MyYup

