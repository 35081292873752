import React, { useEffect, useState, useRef, useContext, forwardRef, useImperativeHandle } from 'react'
import { ErrorMessage, useFormik } from 'formik';
import { Link, useParams, useNavigate } from 'react-router-dom'


/* COMPONENTES */
import Add from '../../components/Add'
import MyButton from '../../components/MyButton'
import SweetButton from '../../components/SweetButton';
import LoadingSpinner from '../../components/LoadingSpinner';
import TabNavigation from '../../components/TabNavigation';
import CustomInputText from '../../components/CustomInputText';
import CustomDropdown from '../../components/CustomDropdown';
import CustomInputNumber from '../../components/CustomInputNumber';
import CustomInputMask from '../../components/CustomInputMask';
import CustomInputMonetary from '../../components/CustomInputMonetary';
import { CustonSwal } from '../../components/Swal';

/* UTILITÁRIOS */
import Permission from '../../utils/Permission.js'
import SingHelper from '../../utils/SingHelper';

import * as Yup from 'yup';
import axios from 'axios'

import schemaClass from '../../tables/colaborador'
import MyYup from '../../utils/MyYup';
import Alert from '../../components/Alert.jsx';

const myYup = new MyYup({ teste: "teste" });
myYup.setSchema(schemaClass);


const FragmentForm = forwardRef((props, ref) => {
    const navigate = useNavigate();

    const getHeaders = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
            headers: {
                "Token-Authorization": user.USER_TOKEN,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br"
            }
        };
    }
    /* VARIAVEIS */
    const parentCallback = props.parentCallback;
    const initialValues = {
        "colaborador_id": "",
        "colaborador_name": "",
        "cpf": "",
        "ctps": "",
        "dt_nascimento": "",
        "colaborador_status_id": "",
        "filiacao_01_name": "",
        "filiacao_02_name": "",
        "sexo": "",
        "naturalidade": "",
        "uf": "",
        "estado_civil_id": "",
        "instrucao": "",
        "doc_rg": "",
        "doc_rg_emissao": "",
        "doc_rg_orgao_emissor": "",
        "doc_cnh": "",
        "dt_admissao": "",
        "forma_salario": ""
    };
    const validateSchema = Yup.object().shape({        
        colaborador_name: Yup.string().required("Campo obrigatório"),
        cpf: Yup.string().required("Campo obrigatório"),
        ctps: Yup.string().required("Campo obrigatório"),
        dt_nascimento: Yup.string().required("Campo obrigatório"),
        
        filiacao_01_name: Yup.string().required("Campo obrigatório"),
        filiacao_02_name: Yup.string().required("Campo obrigatório"),
        sexo: Yup.string().required("Campo obrigatório"),
        naturalidade: Yup.string().required("Campo obrigatório"),
        uf: Yup.string().required("Campo obrigatório"),
        estado_civil_id: Yup.string().required("Campo obrigatório"),
        instrucao: Yup.string().required("Campo obrigatório"),
        doc_rg: Yup.string().required("Campo obrigatório"),
        doc_rg_emissao: Yup.string().required("Campo obrigatório"),
        doc_rg_orgao_emissor: Yup.string().required("Campo obrigatório"),
        doc_cnh: Yup.string().required("Campo obrigatório"),
        dt_admissao: Yup.string().required("Campo obrigatório"),
        forma_salario: Yup.string().required("Campo obrigatório")
    });

    const [getAction, setAction] = useState('post');
    const [getModeForm, setModeForm] = useState(props.mode);

    /* COMPONENTES PRIME-REACT */

    const [isActiveLoader, activeLoader] = useState(false)

    /* USE STATE QUE CONTROLA AS ABAS */
    const [getList, setList] = useState([])
    const [getTbInit, setTbInit] = useState([])
    const [getFormData, setFormData] = useState(initialValues);

    const [swalProps, setSwalProps] = useState({});
    const [activeButton, setActiveButton] = useState("");

    const [activeBtnCancel, setActiveBtnCancel] = useState(false);
    const [activeBtnSave, setActiveBtnSave] = useState(false);
    const [activeBtnPatch, setActiveBtnPatch] = useState(false);
    const [onListener, setListener] = useState(false)    //NEW

    const toast = useRef(null);
    const MyButtonSave = useRef();
    const MyButtonCancel = useRef();
    const MyButtonNew = useRef();
    const copyLink = useRef("");

    const show = () => {
        toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };

    /* FUNÇÕES PRINCIPAIS*/
    /* ESSA FUNÇÃO É RESPONSÁVEL POR FAZER O RESET NO FORMIK */
    const handleClickCancel = (name) => {
        activeLoader(true);
        setActiveButton(name)
        setModeForm("post");
        formik.resetForm();
    };


    /* FUNÇÃO RESPONSÁVEL PELA EDIÇÃO, POPULANDO O PAYLOAD NO FORMIK */
    useImperativeHandle(ref, () => ({
        populaForm: (param) => {
            console.log(props.mode);
            setFormData(schemaClass.sanitize(param))
            setModeForm(props.mode)
            navigate(`/admin/register/colaborador/${param.colaborador_id}/patch`);
        }
    }));




    const formik = useFormik({
        initialValues,
        validationSchema: validateSchema,
        onSubmit: (payload) => {
            //console.clear();
            setActiveButton("btnSave")
            activeLoader(true);
            const _id = schemaClass.getPrimaryKey(payload) >= 1 ? `/${payload.colaborador_id}` : "";
            console.log("_id_id", _id);
            /* prepara pra enviar o post ou patch */
            const instance = axios.create({
                baseURL: process.env.REACT_APP_HOST_API_CLIENT,
                timeout: 5000,
                headers: getHeaders().headers
            });
            const httpMethod = getModeForm;
            
            // Dados que você deseja enviar ou atualizar (depende do verbo)
            const data = schemaClass.beforeSend(payload);
            // URL do endpoint
            const url = `/api/v1/colaborador${_id}`; // Observe que você pode usar caminhos relativos com a instância
            
            //console.log('data, url, method, getModeForm:', data, url, httpMethod, getModeForm);
            console.log("SUBMIT PAYLOAD: " + getModeForm, payload,data);

            // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
            instance[getModeForm](url, data)
                .then((response) => {
                    console.log("Response", response);
                    formik.resetForm()
                    if (response.status === 201) {
                        CustonSwal(response.data.result_code);
                        activeLoader(false);

                        if (getModeForm === "post") {
                            payload.colaborador_id = response.data.result;
                            parentCallback({ "key": data.result, "payload": payload, "action": "ADD" });
                            formik.resetForm();
                        }
                        if (getModeForm === "patch") {
                            setModeForm("post")
                            console.log(":: DADOS DO FORMIK ::", payload);
                            parentCallback({ "key": payload.colaborador_id, "payload": payload, "action": "UPDATE" });
                            formik.resetForm();
                        }
                    }
                    console.log(`Resposta da solicitação ${httpMethod.toUpperCase()}:`, response);
                })
                .catch((error) => {
                    console.error(`Erro na solicitação ${httpMethod.toUpperCase()}:`, error);
                    CustonSwal(error.response.data.resume.failure.fault.fault_code);
                    activeLoader(false);
                });
        },
        validateOnBlur: false, // Definir validateOnBlur como true
    });




    useEffect(() => {
        //        activeLoadingDiv(true);
        (async () => {
            let headers = getHeaders();
            headers.params = {
                tables: [
                    /* {
                        table: "tb_course",
                        asc: "course_name",
                        where: "course_"
                    }, */
                    {
                        table: "tb_regedit"
                    }/* ,
                {
                    table: "tb_class_modality"
                },
                {
                    table: "tb_city"
                } */
                ]
            };
            await axios
                .get(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/regedit`, headers)
                .then(response => {
                    //console.clear();              
                    if (response.status == 200) {
                        console.log("`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/regedit` :: response", response.data);
                        let responseData = response.data;
                        setTbInit(responseData);
                    }
                })
                .catch(r => {
                    console.log("~~~~~~~~~~~~~ | catch", r);
                })
        })();
    }, []);

    useEffect(() => {
        setModeForm(getModeForm)
        activeLoader(false);
        formik.setValues(getFormData);
    }, [getFormData])

    return (
        <div id="panel-2" className="panel">
            <div className="panel-hdr">
                {<h2>
                    Cadastro {getModeForm}
                </h2>}
                <div className="panel-toolbar">
                    { /*<button className="btn btn-panel" data-action="panel-collapse" data-toggle="tooltip" data-offset="0,10" data-original-title="Collapse"></button>
                <button className="btn btn-panel" data-action="panel-close" data-toggle="tooltip" data-offset="0,10" data-original-title="Close"></button> */}
                    <button className="btn btn-panel" data-action="panel-fullscreen" data-toggle="tooltip" data-offset="0,10" data-original-title="Fullscreen"></button>
                </div>
            </div>
            <div className="panel-container show">
                {
                    getModeForm == "patch" && getFormData.length == 0 ?
                        <div className="panel-content">
                            <Alert text={<><strong>Sinto muito!!</strong> Não foi enviado uma informação para edição</>} icon="" className="danger" iconAlign="left" />
                        </div> : (
                            <>
                                {<div className="panel-content">
                                    {/*<div className="panel-tag">
                                    <p>Aqui vai uma descrição opcional<code>Code</code>. Continua descrição.</p>
                                    <p>Subdescrição opcional</p>
                                </div>*/}
                                </div>}
                                <div className="panel-content p-0">
                                    {getTbInit.length === 0 ? <LoadingSpinner /> :
                                        <form onSubmit={formik.handleSubmit} className="validate m-t needs-validation needs-validation" novalidate id="form-create-update">

                                            <div className="panel-content pt-0">
                                                <h3>Dados Pessoais</h3>
                                                <hr />
                                                <div className="form-row">
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='colaborador_name' id="validationCustom01" required placeholder="Nome completo" label='Nome do colaborador' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputMask field='cpf' id="validationCustom01" required placeholder="Cpf do colaborador" label='CPF' formik={formik} args={{ action: getAction, mask: "999.999.999-99" }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='ctps' id="validationCustom01" required placeholder="Nº CTPS" label='Número da carteira de trabalho' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputMask field='dt_nascimento' id="validationCustom01" required placeholder="" label='Data de nascimento' formik={formik} args={{ action: getAction, mask: "99/99/9999" }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='filiacao_01_name' id="validationCustom01" required placeholder="Nome completo da mãe" label='Nome da mãe' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='filiacao_02_name' id="validationCustom01" required placeholder="Nome completo do pai" label='Nome do pai' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">

                                                        <CustomDropdown
                                                            field="sexo"
                                                            label="Sexo"
                                                            options={[getTbInit, 'tb_gender', 'gender_name', 'gender_id']}
                                                            labelField="name"
                                                            listener={onListener}
                                                            //onBlurHandler={() => loadConfigCertificate()}
                                                            placeholder=""
                                                            formik={formik}
                                                        />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='naturalidade' id="validationCustom01" required placeholder="" label='Naturalidade' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='uf' id="validationCustom01" required placeholder="Estado da federação" label='UF' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomDropdown
                                                            field="estado_civil_id"
                                                            label="Estado civil"
                                                            options={[getTbInit, 'tb_estado_civil', 'estado_civil_name', 'estado_civil_id']}
                                                            labelField="name"
                                                            listener={onListener}
                                                            //onBlurHandler={() => loadConfigCertificate()}
                                                            placeholder=""
                                                            formik={formik}
                                                        />

                                                    </div>

                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='instrucao' id="validationCustom01" required placeholder="" label='Instrução' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                </div>
                                                <h3>Documentos</h3>
                                                <hr />
                                                <div className="form-row">

                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='doc_rg' id="validationCustom01" required placeholder="" label='Número do RG' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputMask field='doc_rg_emissao' id="validationCustom01" required placeholder="" label='Data de emissão' formik={formik} args={{ action: getAction, mask: "99/99/9999" }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='doc_rg_orgao_emissor' id="validationCustom01" required placeholder="" label='Órgão emissor' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='doc_cnh' id="validationCustom01" required placeholder="" label='Nº CNH' formik={formik} args={{ action: getAction }} />
                                                    </div>
                                                </div>
                                                <h3>Informações contratuais</h3>
                                                <hr />
                                                <div className="form-row">
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputMask field='dt_admissao' id="validationCustom01" required placeholder="" label='Data de admissão' formik={formik} args={{ action: getAction, mask: "99/99/9999" }} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <CustomInputText field='forma_salario' id="validationCustom01" required placeholder="" label='Forma de pagamento' formik={formik} args={{ action: getAction }} />
                                                    </div>

                                                    {/*--- FIM ---*/}
                                                </div>
                                            </div>

                                            <div className="panel-content border-faded border-left-0 modal-footer border-right-0 border-bottom-0 d-flex flex-row   bg-faded">
                                                {/* <div className="custom-control custom-checkbox flex-grow-1">
        <input type="checkbox" className="custom-control-input" id="invalidCheck" required />
        <label className="custom-control-label" for="invalidCheck">Cliente ciente dos termos? <span className="text-danger">*</span></label>
        <div className="invalid-feedback">
            Você deve selecionar o seguinte campo
        </div>
    </div> */}

                                                {
                                                    getModeForm == "patch" &&
                                                    <MyButton
                                                        name="btnCancel"
                                                        text="Cancelar"
                                                        type="button"
                                                        loading={isActiveLoader}
                                                        disabled={isActiveLoader}
                                                        activeButton={activeButton}
                                                        onClick={(el) => handleClickCancel("btnCancel")}
                                                        icon="fal fa-times"
                                                        btnClass="btn btn-default ml-auto" />
                                                }
                                                {
                                                    getModeForm == "patch" &&
                                                    <MyButton
                                                        name="btnPatch"
                                                        text="Novo"
                                                        type="button"
                                                        loading={isActiveLoader}
                                                        disabled={isActiveLoader}
                                                        activeButton={activeButton}
                                                        onClick={(el) => handleClickCancel("btnPatch")}
                                                        icon="fal fa-plus"
                                                        btnClass="btn btn-success ml-2" />
                                                }
                                                <MyButton
                                                    name="btnSave"
                                                    text="Salvar"
                                                    type="submit"
                                                    loading={isActiveLoader}
                                                    disabled={isActiveLoader}
                                                    activeButton={activeButton}
                                                    icon="fal fa-save"
                                                    btnClass="btn btn-primary ml-2" />

                                            </div>

                                        </form>
                                    }
                                </div>
                            </>
                        )
                }

            </div>
        </div>
    );
})

export default FragmentForm;