import axios from "axios";
import Swal from "sweetalert2";
import SingHelper from "../../../utils/SingHelper";

//import schemaClass from "../../pages/Subscriber/Scheme";

///    export const useApi = () => ({

export const ServicesFolhaPgtoItem = {
    headers: () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
            param: {
                "Token-Authorization": user.USER_TOKEN,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br"
            }
        };
    },

    handlerSave: async (data, args) => {
        //console.log('%c DADOS ENVIADOS PRA API: ', 'color: #999; background-color: yellow;', SingHelper.sanitizeObject(data, schemaCourse), data, schemaCourse);

        /*  if (args.action == "create") {
 
             return await axios
                 .post(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso`, SingHelper.sanitizeObject(data, schemaCourse), ServicesFolhaPgtoItem.headers())
                 .then((r) => {
                     return { success: true, status: r.data.status, result: r.data.result };
                 }).catch(r => {
                     return { success: false, status: r.response.data.status, resume: r.response.data.resume };
                 });
         } else {
             console.log("[[[[ data,schemaCourse ]]]]", SingHelper.sanitizeObject(data, schemaCourse));
             return await axios
                 .patch(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso/${args.params.id}`, SingHelper.sanitizeObject(data, schemaCourse), ServicesFolhaPgtoItem.headers())
                 .then((r) => {
                     return { success: true, status: r.data.status, result: r.data.result };
                 }).catch(r => {
                     return { success: false, status: r.response.data.status, resume: r.response.data.resume };
                 });
 
         } */
    },

    handlerSaveClass: async (data, args) => {
        /* console.log('%c DADOS ENVIADOS PRA API: ', 'color: #999; background-color: yellow;', SingHelper.sanitizeObject(data, schemaClass), data, schemaClass);

        if (args.action == "create") {

            return await axios
                .post(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso`, SingHelper.sanitizeObject(data, schemaClass), ServicesFolhaPgtoItem.headers())
                .then((r) => {
                    return { success: true, status: r.data.status, result: r.data.result };
                }).catch(r => {
                    return { success: false, status: r.response.data.status, resume: r.response.data.resume };
                });
        } else {
            console.log("[[[[ data,schemaClass ]]]]", SingHelper.sanitizeObject(data, schemaClass));
            return await axios
                .patch(`${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/ingresso/${args.params.id}`, SingHelper.sanitizeObject(data, schemaClass), ServicesFolhaPgtoItem.headers())
                .then((r) => {
                    return { success: true, status: r.data.status, result: r.data.result };
                }).catch(r => {
                    return { success: false, status: r.response.data.status, resume: r.response.data.resume };
                });

        } */
    },
    instance: async (method, url, data) => {
        return axios.create({
            baseURL: process.env.REACT_APP_HOST_API_CLIENT,
            timeout: 5000,
            headers: ServicesFolhaPgtoItem.headers().param
        })[method](url, data);
    },
    save: async (payload, args) => {
        /*  const _id = schemaClass.getPrimaryKey(payload) >= 1 ? `/${payload.subscriber_id}` : "";
 
         // prepara pra enviar o post ou patch 
         const method = args.method;
 
         // Dados que você deseja enviar ou atualizar (depende do verbo)
         const data = schemaClass.beforeSend(payload);
 
         // URL do endpoint
         const url = `/api/v1/subscriber${_id}`; // Observe que você pode usar caminhos relativos com a instância
 
         // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
         console.log('%c ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| PÁGINA HTML FORM :: POST API :: |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~', 'color: #fff; background-color: red;');
         console.log(data);
 
         return await ServicesFolhaPgtoItem.instance(method, url, data)
             .then((response) => {
                 console.log(":::: response API ::::", response.data);
                 return response;
             })
             .catch((error) => {
                 console.error(`Erro na solicitação ${method.toUpperCase()}:`, error);
             }); */

    },
    patch: async (payload, args) => {
        /* //const _id = schemaClass.getPrimaryKey(payload) >= 1 ? `/${payload.subscriber_id}` : "";

        / prepara pra enviar o post ou patch
        const method = args.method;

        // Dados que você deseja enviar ou atualizar (depende do verbo)
        const data = schemaClass.beforeSend(payload);

        // URL do endpoint
        const url = `/api/v1/subscriber/${payload.subscriber_id}`; // Observe que você pode usar caminhos relativos com a instância

        // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
        console.log('%c ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| PÁGINA HTML FORM :: POST API :: |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~', 'color: #fff; background-color: red;');
        console.log(data);

        return await ServicesFolhaPgtoItem.instance(method, url, data)
            .then((response) => {
                console.log(":::: response API ::::", response.data);
                return response;
            })
            .catch((error) => {
                console.error(`Erro na solicitação ${method.toUpperCase()}:`, error);
            }); */

    },
    findAll: async (args) => {
        //const _id = schemaClass.getPrimaryKey(payload) >= 1 ? `/${payload.subscriber_id}` : "";

        /* prepara pra enviar o post ou patch */
        const method = 'get';

        // Dados que você deseja enviar ou atualizar (depende do verbo)

        let params = args.params ?? `${args.params}`;
        // URL do endpoint
        const url = `/api/v1/folha_pgto_item${params}`; // Observe que você pode usar caminhos relativos com a instância

        // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
        console.log('%c ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| PÁGINA HTML FORM :: POST API :: |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~', 'color: #fff; background-color: red;');
        console.log(args);

        return await ServicesFolhaPgtoItem.instance(method, url, {})
            .then((response) => {
                console.log(":::: response API ::::", response.data);
                return response;
            })
            .catch((error) => {
                console.error(`Erro na solicitação ${method.toUpperCase()}:`, error);
            });

    },
    put: async (payload, args) => {
        /* const _id = schemaClass.getPrimaryKey(payload) >= 1 ? `/${payload.subscriber_id}` : "";

        // prepara pra enviar o post ou patch 
        const method = args.method;

        // Dados que você deseja enviar ou atualizar (depende do verbo)
        const data = schemaClass.beforeSend(payload);

        // URL do endpoint
        const url = `/api/v1/subscriber${_id}`; // Observe que você pode usar caminhos relativos com a instância

        // Realiza a solicitação usando o verbo HTTP dinâmico com a instância Axios
        console.group('HTTP');
        console.log('%c ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~| SERVICE HTTP :: POST ENVIADO PARA A API :: |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~', 'color: #fff; background-color: red;');
        console.log(data);
        console.groupEnd()

        return await ServicesFolhaPgtoItem.instance(method, url, data)
            .then((response) => {
                console.log(":::: response API ::::", response.data);
                return response;
            })
            .catch((error) => {
                console.error(`Erro na solicitação ${method.toUpperCase()}:`, error);
            }); */

    },

    crudDelete: async (id, args = {}) => {
        console.log('%c DADOS ENVIADOS PRA API [DELETE]: ', 'color: #999; background-color: yellow;', id);

        const method = 'delete';
        return await ServicesFolhaPgtoItem.instance(method, `${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/subscriber/${id}`)
            .then((r) => {
                console.log(":::: response API ::::", r.data);
                return { success: true, status: r.data.status, result: r.data.result };
            })
            .catch((r) => {
                return { success: false, status: r.response.data.status, resume: r.response.data.resume };
            });


    }
}