
/* 

if (in_array("777", $exp))
    $this->boolean = true;
else if (in_array("A00", $exp) && $action == "show")
    $this->boolean = true;
else if (in_array("A01", $exp) && $action == "create")
    $this->boolean = true;
else if (in_array("A02", $exp) && $action == "list")
    $this->boolean = true;
else if (in_array("A03", $exp) && $action == "update")
    $this->boolean = true;
else if (in_array("A04", $exp) && $action == "delete")
    $this->boolean = true;
else if (in_array("A05", $exp) && $action == "search")
    $this->boolean = true;
else if (in_array("A06", $exp) && $action == "view")
    $this->boolean = true;
else if (in_array("A07", $exp) && $action == "createPermission")
    $this->boolean = true;
else if ($action_person != null)
    $this->boolean = false;
else {

arr['show'] = "A00";
arr['create'] = "A01";
arr['list'] = "A02";
arr['update'] = "A03";
arr['delete'] = "A04";
arr['search'] = "A05";
arr['view'] = "A06";


    
*/
class PermissionManager {
    constructor() {
        this.actions_of_CS = [];
        this.PERMISSION = [];
        this.permission_of_CS = [];
        this.thisAction = "";
        this.thisScreenId = "";
        this.thisCurrentClientId = "";
        this.verbs = {
            show: "A00",
            post: "A01",
            create: "A01",
            list: "A02",
            patch: "A03",
            delete: "A04",
            search: "A05",
            view: "A06"/* ,
            A00: "show",
            A01: "create",
            A02: "list",
            A03: "update",
            A04: "delete",
            A05: "search",
            A06: "view", */

        }
    };
    setAction(action) {
        this.thisAction = action;
    }
    getAction() {
        return this.thisAction;
    }
    setPermission(permission) {
        this.PERMISSION = permission;
    }
    getPermission() {
        return this.PERMISSION;
    }
    setScreenId(screenId) {
        this.thisScreenId = screenId;
    }
    getScreenId() {
        return this.thisScreenId;
    }
    setCurrentClientId(currentClientId) {

        this.thisCurrentClientId = currentClientId;
    }
    getCurrentClientId() {
        return this.thisCurrentClientId;
    }
    isInclude() {

    }
    //
    flag(flagNeed, args = {}) {
        /* console.log('%c FLAG RECEBIDA: ', 'color: #000; background-color: purple;', flagNeed);
        console.log('%c this.PERMISSION: ', 'color: #000; background-color: purple;', this.PERMISSION); */
        console.clear();

        // Verifica se é uma permissão genérica
        if (flagNeed in this.verbs)
            flagNeed = this.verbs[flagNeed];

        //Verifico se o screen_id e client_id estão presentes em algum elemento do array permission
        const index = this.PERMISSION.find(el => el.screen_id == this.getScreenId() && el.client_id_array.split(",").includes(this.getCurrentClientId()));
        console.log("permission", index);
        if (flagNeed) {

            if (index) {
                //1 - Extrai das ações da página da propriedade [permission]; 2 - Popula o array this.actions_of_CS com as ações encontradas
                this.actions_of_CS = index.permission.match(/A\d{2}/g);
                //converto a propriedade [permission] em array
                this.permission_of_CS = index.permission.split(",");
                this.permission_of_CS = this.actions_of_CS.concat(this.permission_of_CS);

                //Verifico se eu tenho a permissão que o parametro está pedindo
                if (this.permission_of_CS.includes(flagNeed))
                    return true;
                else
                    return false;
                //Verifico se o usuário tem 

            }
            else
                return false
        } else {
            return index;
        }

    }

};
const Permission = new PermissionManager();

export default Permission;