import moment from 'moment';

const SingMask = {
    toMonetary: (value, key, args = {}) => {
        //console.log("VALUE",value);
        let options = {
            currency: "BRL",
            style: "currency",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }
        let format = "pt-br";
        let REAL = new Intl.NumberFormat(format, options);
        return REAL.format(value);
    },
    toDate: (value, key, args = {}) => {
        //console.log("~~~~~~~~~~ value", value, key);
        if (value) {
            let formatIsBr = value.indexOf("-") > -1 ? false : true;
            //console.log("formatIsBr", formatIsBr,value);//dmYHi

            if ((formatIsBr && (key === "dmYHi" || key === "dmYHis" || key === "dmY")) || (!formatIsBr && (key === "YmdHi" || key === "YmdHis" || key === "Ymd")))
                return value;

            let format = "";
            if (value === null || value === undefined)
                return;
            let split = value.split("-");
            if (value === "0000-00-00 00:00:00")
                return <span><i className="fa fa-exclamation-triangle text-danger" title="Data está incorreta"></i></span>;
            if (value === null)
                return "";
            if (key === "dmY") {
                format = 'DD/MM/YYYY';
            }

            if (split.length > 0 && split[0] > 1000)
                format = 'DD/MM/YYYY';

            if (key === "dmYHis")
                format = 'DD/MM/YYYY HH:mm:ss';

            if (key === "dmYHi")
                format = 'DD/MM/YYYY HH:mm';

            //console.log("Chegou ate aqui",format,value);
            return moment(value).format(format);
        }
    },
    /* FAZ AS CONVERSOES DE CPF E CNPJ BRASIL E PORTUGAL LEMBRANDO QUE É APENAS UMA MÁSCARA E NÃO FAZ CONVERSÃO*/
    toDocument: (value, key = "M", args = {}) => {
        value = value.replace(/\D/g, '');

        if (value) {

            if (key === "J")
                return value.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
            else if (key === "M" || key === "F") {
                if (value.length == 14)
                    return value.toString().substring(3).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                else if (value.length == 11)
                    return value.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
                else if (value.length > 14)
                    return "ERRO"
                else
                    return "erro"
            }
            else {

            }
        }
    },
    toPhone: (value, key, args = {}) => {

        let newValue = `055${value}`;
        let ddi = [];
        ddi[55] = 1;
        if (value) {
            return newValue.replace(/^0+/, '').toString().replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, '+$1 ($2) $3 $4-$5')
        }
    }
};

export default SingMask;
