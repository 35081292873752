import React, { useEffect, useState, useRef, useContext } from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom'

import { useLocation } from 'react-router'
import { AuthContext } from '../../Contexts/Auth/AuthContext'
import { loadFromLocalStorage } from '../../utils/localStorageUser'

import Aside from '../../fragments/Aside';
import Header from '../../fragments/Header';
import Footer from '../../fragments/Footer';

/* UTILITÁRIOS */
import Permission from '../../utils/Permission.js'

/* COMPONENTES */
import { CustonSwal } from '../../components/Swal';


import axios from 'axios'
import * as Yup from 'yup';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';

import Denny from '../Denny/Denny';
import myJson from '../../utils/json.json';

import schemaClass from '../../tables/folha_pgto_item'
import MyYup from '../../utils/MyYup';
import FragmentForm from './FragmentForm.jsx';
import FragmentList from './FragmentList.jsx';
import FragmentSearch from './FragmentSearch.jsx';
import SingHelper from '../../utils/SingHelper.js'

const myYup = new MyYup({ teste: "teste" });

myYup.setSchema(schemaClass);

function Folha_pgto_item() {
    const location = useLocation()
    const navigate = useNavigate();

    const getHeaders = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        return {
            headers: {
                "Token-Authorization": user.USER_TOKEN,
                "Is-Api": true,
                "Entity-Public-Token": "host.com.br"
            }
        };
    }


    const [getAction, setAction] = useState('create');
    const [getModeForm, setModeForm] = useState("post");

    /* COMPONENTES PRIME-REACT */
    const [isActiveLoadingDiv, activeLoadingDiv] = useState(false);
    const [isActiveLoader, activeLoader] = useState(false)
    const [getActiveTab, setActiveTab] = useState('all')
    const [showLoader, setShowLoader] = useState(false)
    /* USE STATE QUE CONTROLA AS ABAS */
    const [getList, setList] = useState([])
    const [getTbInit, setTbInit] = useState([])
    const [getListOnRead, setListOnRead] = useState({})//Está sempre será carregada e populada para evitar uma nova busca no banco de dados quando, por exemplo um novo registro é acrescido.


    const toast = useRef(null);
    const ComponentFragmentList = useRef();
    const ComponentFormData = useRef();

    const show = () => {
        //toast.current.show({ severity: 'success', summary: 'Sucesso!', detail: 'Link foi copiado', life: 3000 });
    };

    const refreshList = (args) => {
        console.log(" INDEX.JS:: refreshList::", args);
        ComponentFragmentList.current?.refreshTableList(args)
    };

    const populaForm = (param) => {
        console.log("populaForm");
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        ComponentFormData.current?.populaForm(param)
    };


    /*-- Parametros --
        listOnRead: è usado pra dizer que a lista setListOnRead deve ser preenchida, assim em uma eventual acrescimo esse será buscado e assim evita uma desnecessária busca no BD.
    */
    const getListBd = async (args) => {
        console.log("ARGS RECEBIDOS: ", args);
        let page = args.page ?? 1;
        let limit = args.limit ?? 30;
        let sort = args.sort ?? 'C.colaborador_name+asc';
        let left_join = args.left_join ?? '&left_join=true';

        let count_all_rows = args.count_all_rows ?? false;
        let show_paginator = args.show_paginator ?? false;

        let origin = args.origin && args.origin == "search" ? `&search=true` : '';
        let q = args.q ? `&${args.q}` : '';
        let folha_pgto_id = window.location.href.split("/").pop()
        console.log("window.location.href", window.location.href.split("/").pop(), folha_pgto_id);

        let url = `${process.env.REACT_APP_HOST_API_CLIENT}/api/v1/folha_pgto_item?folha_pgto_id=${folha_pgto_id}&left_join=true&count_all_rows=${count_all_rows}&limit=${limit}&page=${page}&sort=${sort}${origin}${left_join}${q}`;
        console.group("~~~~~~|  GetListBd :: Buscando dados no banco ~> FOLHA PAGAMENTO ITENS ");

        console.log("URL: ", url);
        activeLoadingDiv(true);
        await axios
            .get(url, getHeaders())
            .then(response => {
                console.log("~~~~ CARREGANDO FOLHA_PGTO_ITEM ~~~~", response.data);
                if (response.data.status == 200) {
                    activeLoadingDiv(false);
                    setShowLoader(true)
                    let res = response.data
                    let payload = { payload: res.result, result_row_count_total: res.result_row_count_total, show_paginator: show_paginator, action: "ALL" };
                    if (args.listOnRead)
                        setListOnRead(payload);
                    setList(payload);
                }
            })
            .catch(r => {
                console.log("erro", r);
                console.log("r.response.data.result_code", r.response.data.resume.failure.fault.fault_code);
                CustonSwal(r.response.data.resume.failure.fault.fault_code);

            })
        console.groupEnd();
    }

    /* useForm */
    const SCREEN_ID = 502;
    const { setAuth, auth, provider_session } = useContext(AuthContext)
    console.log("provider_session", provider_session);
    Permission.setScreenId(SCREEN_ID);
    Permission.setCurrentClientId(provider_session.CURRENT_CLIENT_ID);
    Permission.setPermission(loadFromLocalStorage('permission'));


    /* CALLBACKS */
    const callbackPaginator = (args) => {
        console.log("callbackPaginator RECEBIDOS,", args);
        getListBd({ page: args.page, limit: 10, sort: 'client_name+asc', count_all_rows: true, show_paginator: true, origin: args.origin, q: args.q })
    }

    const callbackFromFormData = (args) => {
        //console.clear();            
        console.log('%c ----------------------------------------- PÁGINA INDEX - [callbackFromFormData] -----------------------------------------', 'color: #000; background-color: green;');
        console.log(args);
        if (args.action == "RELOAD") {
            console.log(" ----------------- ~~ RELOAD ~~ ---------------------");

            refreshList(args);

        } else if (args.action == "UPDATE") {
            console.log(" ----------------- ~~ UPDATE ~~ ---------------------");
            refreshList(args);

        } else if (args.action == "ADD") {
            console.log(" ----------------- ~~ ADD ~~ ---------------------");
            console.log(args);
            if (args.insert_colaborador) {
                console.log("aqui1");
                if (getList.payload && getList.payload.length > 0)
                    args.payload = [].concat(args.payload.map(el => ({ colaborador_name: el.name, colaborador_id: el.code, valor: "",status_name:"ativo", _created_at:SingHelper.now() })), getList.payload);
                else
                    args.payload = args.payload.map(el => ({ colaborador_name: el.name, colaborador_id: el.code, valor: "",status_name:"ativo", _created_at:SingHelper.now() }))
                setList(args);
            }
            else {
                console.log("aqui2");
                /* if (getList.payload && getList.payload.length > 0)
                    args.payload = [].concat([{ colaborador_name: args.payload.name, colaborador_id: args.payload.code, valor: "" }], getList.payload);
                else
                    args.payload = args.payload */
            }
            //args.payload = args.payload.map(el => ({ colaborador_name: el.name, colaborador_id: el.code, valor: "" }))

            //setList(args);


            //setList((prevData)=>({...prevData,payload}));

                //setList(args.payload);

/*                 getListOnRead.payload.pop();
                let newGetListOnRead = getListOnRead;
                args.result_row_count_total = newGetListOnRead.payload.length + 1;
                newGetListOnRead.payload.unshift(args.payload)
                args.payload = newGetListOnRead.payload;
                console.log("refreshList before send: ");
                refreshList(args)
 */            } else {
            console.log("caiu aqui else então algo deve ser feito");
        }
        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");

    }

    const parentCallbackHtmlList = (el) => {
        console.log('%c PÁGINA INDEX - [parentCallbackHtmlList]: ', 'color: #000; background-color: green;');
        console.log(el);
        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
        setModeForm("patch");
        populaForm(el);
    }
    const parentCallbackHtmlListSave = (el, i) => {
        console.clear();
        console.log('%c ########################### PÁGINA INDEX - [parentCallbackHtmlListSave]: ', 'color: #000; background-color: green;');
        console.log(el);
        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~");
        ComponentFormData.current?.saveItem(el, i)
    }

    useEffect(() => {
        console.log('%c useEffect - [localtion]: ', 'color: #000; background-color: green;');
    }, [location])

    useEffect(() => {
        console.log('%c useEffect - [localtion]: ', 'color: #000; background-color: green;');
    }, [getList])

    useEffect(() => {
        console.log(`%c useEffect - [getActiveTab]: ${getActiveTab} `, 'color: #000; background-color: green;');
        if (getActiveTab === "all") {
            getListBd({ listOnRead: true });
        }
    }, [getActiveTab])


    return (
        <div className="page-wrapper">

            <div className="page-inner">
                <Aside />
                <div className="page-content-wrapper">
                    <Header />
                    <main id="js-page-content" role="main" className="page-content">
                        <ol className="breadcrumb page-breadcrumb">
                            <li className="breadcrumb-item">Admin</li>
                            <li className="breadcrumb-item">Cadastro</li>
                            <li className="breadcrumb-item active">Folha de pagamento itens</li>
                            <li className="position-absolute pos-top pos-right d-none d-sm-block"><span className="js-get-date"></span></li>
                        </ol>
                        <div className="subheader">
                            <h1 className="subheader-title">
                                <i className='fal fa-info-circle'></i> ÍTENS DA FOLHA DE PAGAMENTO
                                <small>
                                    
                                </small>
                            </h1>
                        </div>


                        {/* Formulário */}
                        <FragmentForm parentCallback={callbackFromFormData} mode={getModeForm} ref={ComponentFormData} />
                        {/* <FragmentSearch parentCallback={callbackFromFormData} mode={getModeForm} /> */}
                        <FragmentList data={getList} loading={showLoader} parentCallback={parentCallbackHtmlList} parentCallbackSave={parentCallbackHtmlListSave} callbackPaginator={callbackPaginator} ref={ComponentFragmentList} />
                    </main>
                    <Footer />
                </div>
            </div >

        </div >

    );
}

export default Folha_pgto_item;