import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import SingHelper from '../utils/SingHelper';

const CustomDropdown = ({ field, formik, options, listener, onBlurHandler, labelField, placeholder, label }) => {

  const [selectedItem, setSelectedItem] = useState(null);
  const [getOptions, setOptions] = useState([]);

  const getDropdownOptions = (a) => {

    //console.log("getDropdownOptions: ", a);
    return SingHelper.converteToDropDown(
      a[0], a[1], a[2], a[3]
    );
  };
  useEffect(() => {    
    console.log("DUSTOMDROPDOWN options", options);
    updateOptions();
  }, []);

  useEffect(() => {
    if (listener) {
      let newListener = options;
      //newListener[0]['tb_certificate_config'] = listener;      
      updateOptions(newListener);


    }
  }, [listener]);

  const updateOptions = (args) => {
    const newOptions = getDropdownOptions(args ?? options);
    //console.log("newOptions", newOptions);
    if (newOptions)
      setOptions(newOptions);
  };

  const handleDropdownChange = (e) => {
    //console.clear();
    if (onBlurHandler)
      onBlurHandler();
    console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx: "+field, e.value.code.toString(),getOptions.find(option => option.code === e.value.code));
    formik.setFieldValue(field, e.value.code.toString()); // Atualiza o valor no Formik
  };
  return (
    <>
      {
        label ? <label htmlFor={field}>{label}</label>:''
      }
      <Dropdown
        id={field}
        name={field}
        value={getOptions.find(option => option.code == formik.values[field])}
        onChange={handleDropdownChange}
        options={getOptions}
        optionLabel={labelField}
        placeholder={placeholder}
        className={`form-control ${classNames({ 'p-invalid': formik.touched[field] && formik.errors[field] })}`}
        emptyMessage="Não há resultados"
      />
      {formik.touched[field] && formik.errors[field] && <div className='formik-error'>{formik.errors[field]}</div>}
    </>
  );
};

export default CustomDropdown;
